import styled, { css, keyframes } from "styled-components";
import { sigaDarkBlueOpaque } from "../../constants";
import { useAppSelector } from "../../hooks";

const PulseRed = keyframes`
    0% {
      transform: scale(0.95);
      box-shadow: 0 0 0 0 ${sigaDarkBlueOpaque(0.7)};
    }
    
    70% {
      transform: scale(1);
      box-shadow: 0 0 0 10px ${sigaDarkBlueOpaque(0)};
    }
    
    100% {
      transform: scale(0.95);
      box-shadow: 0 0 0 0 ${sigaDarkBlueOpaque(0)};
    }
`;

const PulseBlue = keyframes`
    0% {
      transform: scale(0.95);
      box-shadow: 0 0 0 0 rgba(0, 139, 210, 0.7);
    }
    
    70% {
      transform: scale(1);
      box-shadow: 0 0 0 10px rgba(0, 139, 210, 0);
    }
    
    100% {
      transform: scale(0.95);
      box-shadow: 0 0 0 0 rgba(0, 139, 210, 0);
    }
`;

const pulseBlueAnimation = css`
  animation: ${PulseBlue} 2s infinite;
`;
const pulseRedAnimation = css`
  animation: ${PulseRed} 2s infinite;
`;

const NumberCircleContainer = styled.div.attrs(
  (props: {
    size?: string;
    fontSize?: string;
    spanHeight?: string;
    isPulsing: boolean;
    red?: boolean;
  }) => props
)`
  display: flex;
  width: ${(props) => props.size ?? "44px"};
  height: ${(props) => props.size ?? "44px"};
  background-color: ${(props) =>
    props.red ? sigaDarkBlueOpaque(1) : `rgba(0, 139, 210, 1)`};
  box-shadow: 0 0 0 0
    ${(props) => (props.red ? sigaDarkBlueOpaque(1) : `rgba(0, 139, 210, 1)`)};
  color: #fff;
  ${(props) =>
    props.isPulsing
      ? props.red
        ? pulseRedAnimation
        : pulseBlueAnimation
      : ""};
  border-radius: 100%;
  position: relative;
  span {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    text-align: center;
    height: ${(props) => props.spanHeight ?? "22px"};
    font-size: ${(props) => props.fontSize ?? "20px"};
  }
`;

const NumberCircle: React.FC<{
  nr: number;
  size?: string;
  fontSize?: string;
  spanHeight?: string;
  isPulsing?: boolean;
  red?: boolean;
}> = ({ nr, size, fontSize, spanHeight, red = false, isPulsing = false }) => {
  const lastLevelReached = useAppSelector(
    (state) => state.appData.lastLevelReached
  );
  return (
    <NumberCircleContainer
      size={size}
      isPulsing={isPulsing && !lastLevelReached}
      fontSize={fontSize}
      spanHeight={spanHeight}
      red={red && !lastLevelReached}
    >
      <span>{nr}</span>
    </NumberCircleContainer>
  );
};

export default NumberCircle;
