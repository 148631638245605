import styled from "styled-components";
import { SIGA_MAIN_BLUE } from "../../constants";

const MainTitle = styled.h2.attrs((props: { color: string }) => props)`
  color: ${(props) => props.color || SIGA_MAIN_BLUE};
  font-weight: 700;
  width: 100%;
  text-align: center;
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 29px;
`;

interface TitleProps {
  title: React.ReactElement;
  color?: string;
}

const Title: React.FC<TitleProps> = ({ title, color }) => (
  <MainTitle color={color}>{title}</MainTitle>
);

export default Title;
