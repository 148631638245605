import { TextField } from "@mui/material";
import { useAppDispatch, useAppSelector } from "../../hooks";
import { setPassword } from "../../reducers/appData";
import { Buffer } from "buffer";
import styled from "styled-components";

const encode = (str: string): string =>
  Buffer.from(str, "binary").toString("base64");

const PasswordFormControl = styled.div`
  width: 100%;
  min-height: calc(100vh - 84px);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;

const PasswordTextField = styled(TextField)`
  flex: 0 0 250px;
`;

const PasswordProtect = () => {
  const dispatch = useAppDispatch();
  const password = useAppSelector((state) => state.appData.password);
  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    dispatch(setPassword(e.target.value));
  };
  return encode(password) !== "c2lnYTIwMjJUZXN0IQ==" ? (
    // return false ? (
    <PasswordFormControl>
      <PasswordTextField
        label={"Password"}
        value={password}
        onChange={handleChange}
        type={"password"}
      />
    </PasswordFormControl>
  ) : (
    <></>
  );
};

export default PasswordProtect;
