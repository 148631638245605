import { useIntl } from "react-intl";
import { BreadcrumbData } from "../components/Breadcrumbs/BreadcrumbsContainer";

export const useBreadcrumbs = (): BreadcrumbData[] => {
  const intl = useIntl();
  const breadcrumbs: BreadcrumbData[] = [
    {
      nr: 1,
      theState: "filled",
      title: intl.formatMessage({
        id: "Checklist",
        defaultMessage: "Checklist",
      }),
    },
    {
      nr: 2,
      theState: "disabled",
      title: intl.formatMessage({ id: "Review", defaultMessage: "Review" }),
    },
    {
      nr: 3,
      theState: "disabled",
      title: intl.formatMessage({ id: "AGB", defaultMessage: "AGB" }),
    },
    {
      nr: 4,
      theState: "disabled",
      title: intl.formatMessage({ id: "Done", defaultMessage: "Done" }),
    },
  ];
  return breadcrumbs;
};
