import styled, { keyframes } from "styled-components";
import { TitleRow } from "../TitleRow";
import SVG from "react-inlinesvg";
import greenCheck from "../../assets/greenCheck.svg";
import pdfIcon from "../../assets/pdfIcon.svg";
import { CheckmarkIcon, CheckmarkTitleContainer } from "../../styledComponents";
import { useIntl } from "react-intl";
import { Button, CircularProgress, styled as MuiStyled } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../hooks";
import { useNavigate, useParams } from "react-router-dom";
import usePassword from "../../hooks/usePassword";
import downloadForm from "../../api/downloadForm";
import { useDispatch } from "react-redux";
import { resetAllState } from "../../reducers/appData";
import { EmbedSocialWidget } from "../EmbedSocialWidget";
import scrollFunction from "../../utils/scrollFunction";

const AnimatedfadeIn = keyframes`
  0% { opacity: 0; transform: translateY(20px); }
  100% { opacity: 1; transform: translateY(0px); }
`;

const SuccessWrapper = styled.div`
  width: calc() (100% - 32px);
  padding-left: 16px;
  padding-right: 16px;
  margin-bottom: 80px;
`;

const WidgetArea = styled.div`
  margin-top: 16px;
  width: 100%;
  max-width: 312px;
`;

const BackButton = MuiStyled(Button)`
    color: rgba(0,0,0,0.4);
    text-transform: initial;
    margin-top: 16px;
`;

const MainButtonArea = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 60px;
  opacity: 0;
  animation: ${AnimatedfadeIn} 0.5s 0.9s forwards;
`;

const PDFButton = styled(Button)`
  width: 100%;
  max-width: 312px;
  border-radius: 40px !important;
  text-transform: initial !important;
  font-weight: 300 !important;
  height: 55px;
  position: relative;
  .pdf {
    position: absolute;
    right: 50px;
  }
`;

const WarrantyCheckmarkIcon = styled(CheckmarkIcon)`
  animation: ${AnimatedfadeIn} 0.5s;
`;

const SuccessDescription = styled.div`
  flex: 1;
  font-size: 14px;
  margin-top: 16px;
  color: rgba(0, 0, 0, 0.5);
  p {
    margin: 8px 0;
  }
  opacity: 0;
  animation: ${AnimatedfadeIn} 0.5s 0.7s forwards;
`;

const CheckmarkTitle = styled.h3`
  opacity: 0;
  margin-bottom: 8px;
  margin-top: 48px;
  animation: ${AnimatedfadeIn} 0.5s 0.5s forwards;
`;

const SuccessView = () => {
  const intl = useIntl();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  let componentRef = useRef(null);
  const { lang } = useParams();
  const hasPassword = usePassword();
  const [pdfSending, setPdfSending] = useState(false);
  useEffect(() => {
    if (componentRef?.current) {
      scrollFunction();
    }
  }, [componentRef]);

  const surveyCompleted = useAppSelector(
    (state) => state.appData.lastLevelReached
  );

  const pdfFormData = useAppSelector((state) => state.appData.pdfFormData);

  const downloadPdf = async () => {
    if (!pdfFormData) return;
    setPdfSending(true);
    const res = await downloadForm(pdfFormData);
    const byteArray = new Uint8Array(res.data);
    const blob = new Blob([byteArray], { type: "application/pdf" });
    const downloadLink = document.createElement("a");
    downloadLink.href = URL.createObjectURL(blob);
    downloadLink.download = `${intl
      .formatMessage({
        id: "Warranty letter",
        defaultMessage: "Warranty letter",
      })
      .replace(/\s+/g, "-")}.pdf`;
    downloadLink.click();
    setPdfSending(false);
  };

  const goBackToStart = () => {
    dispatch(resetAllState());
    navigate(`/${lang}`, { replace: true });
  };

  useEffect(() => {
    if (!surveyCompleted) {
      navigate(`/${lang}`, { replace: true });
    }
  }, [surveyCompleted, hasPassword, navigate, lang]);
  return (
    <SuccessWrapper ref={componentRef}>
      <TitleRow
        leftSide={<></>}
        center={
          <>
            <CheckmarkTitleContainer>
              <WarrantyCheckmarkIcon>
                <SVG src={greenCheck} />
              </WarrantyCheckmarkIcon>
              <CheckmarkTitle>
                {intl.formatMessage({
                  id: "Selection successful",
                  defaultMessage: "Selection successful",
                })}
              </CheckmarkTitle>
            </CheckmarkTitleContainer>
            <SuccessDescription>
              <p>
                {intl.formatMessage({
                  id: "You will find your SIGA approval letter below",
                  defaultMessage:
                    "You will find your SIGA approval letter below",
                })}
              </p>
              <p>
                {intl.formatMessage({
                  id: "You will receive this in just a few seconds",
                  defaultMessage: "You will receive this in just a few seconds",
                })}
              </p>
            </SuccessDescription>
          </>
        }
        rightSide={<></>}
      />
      <MainButtonArea>
        <PDFButton
          variant="contained"
          disableElevation
          // onClick={showResults}
          disabled={pdfSending}
          onClick={downloadPdf}
        >
          {intl.formatMessage({
            id: "Warranty letter",
            defaultMessage: "Warranty letter",
          })}
          {pdfSending ? (
            <CircularProgress
              color="info"
              size={16}
              style={{ position: "absolute", right: "50px" }}
            />
          ) : (
            <SVG className="pdf" src={pdfIcon} />
          )}
        </PDFButton>
        <WidgetArea>
          <EmbedSocialWidget />
        </WidgetArea>
        <BackButton variant="text" color="inherit" onClick={goBackToStart}>
          {intl.formatMessage({
            id: "To checklist",
            defaultMessage: "To checklist",
          })}
        </BackButton>
      </MainButtonArea>
    </SuccessWrapper>
  );
};

export default SuccessView;
