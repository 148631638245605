import styled from "styled-components";
import SVG from "react-inlinesvg";
import greenCheck from "../../assets/greenCheck.svg";
import { useAppDispatch, useAppSelector } from "../../hooks";
import { setFormFieldValue, setFormTimestamp } from "../../reducers/appData";
import { useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { useIntl } from "react-intl";
import ContactForm from "./ContactForm";
import { CheckmarkIcon, CheckmarkTitleContainer } from "../../styledComponents";
import scrollFunction from "../../utils/scrollFunction";

const SuccessfulParametersFormContainer = styled.div`
  padding: 0 38px;
  display: flex;
  flex-direction: column;
`;

export interface DialogTitleProps {
  id: string;
  children?: React.ReactNode;
  onClose: () => void;
}

const SuccessfulParametersForm: React.FC<{ lastLevelReached: boolean }> = ({
  lastLevelReached,
}) => {
  const stepRef = useRef(null);
  const intl = useIntl();

  useEffect(() => {
    if (lastLevelReached) {
      scrollFunction((stepRef?.current as any).offsetTop);
    }
  }, [lastLevelReached]);

  return (
    <>
      <SuccessfulParametersFormContainer
        className={`panel ${!lastLevelReached ? "hidden" : "open"}`}
        ref={stepRef}
      >
        <CheckmarkTitleContainer>
          <CheckmarkIcon>
            <SVG src={greenCheck} />
          </CheckmarkIcon>
          <h4>
            {intl.formatMessage({
              id: "Successful Parameters",
              defaultMessage: "Successful Parameters",
            })}
          </h4>
        </CheckmarkTitleContainer>
        <ContactForm />
      </SuccessfulParametersFormContainer>
    </>
  );
};

export default SuccessfulParametersForm;
