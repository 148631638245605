import styled from "styled-components";
import {
  MEDIA_LARGE,
  MEDIA_MEDIUM,
  MEDIA_SMALL,
  MEDIA_SUPER_SMALL,
} from "../../constants";
import desktop from "../../assets/desktop.svg";
import { useIntl } from "react-intl";
import { useLocation } from "react-router-dom";

const BodyContainer = styled.div.attrs((props: { maxWidth: string }) => props)`
  max-width: ${(props) => props.maxWidth};
  margin: 0 auto;
  width: 100%;
  display: block;
  ${MEDIA_LARGE} {
    padding-left: 16px;
    padding-right: 16px;
    width: calc(100% - 32px);
  }
  ${MEDIA_SMALL} {
    // display: none;
    padding-left: 0px;
    padding-right: 0px;
    max-width: 100%;
    width: 100%;
  }
  margin-top: 32px;
  ${MEDIA_SUPER_SMALL} {
    overflow: hidden;
    margin-top: 0px;
  }
`;

const BetaVersionNotice = styled.div.attrs(
  (props: { isWarrantyLetterPage: boolean }) => props
)`
  display: none;
  ${MEDIA_SMALL} {
    display: none;
    max-width: calc(100% - 162px);
    width: 280px;
    margin: 0 auto;
    ${(props) =>
      props.isWarrantyLetterPage === true
        ? "position:relative; top:120px;"
        : ""}
    padding-right: 16px;
    background-image: url(${desktop});
    background-repeat: no-repeat;
    background-position: 16px center;
    height: 100px;
    padding-left: 130px;
  }
`;

const Body: React.FC<{ children?: React.ReactNode; maxWidth: string }> = ({
  children,
  maxWidth,
}) => {
  const location = useLocation();
  const isWarrantyLetterPage =
    location.pathname.indexOf("warranty-letter") !== -1;
  const metaMsg =
    "The beta version of this application is currently only supported on desktop devices";
  const intl = useIntl();
  return (
    <>
      <BetaVersionNotice isWarrantyLetterPage={isWarrantyLetterPage}>
        {intl.formatMessage({ id: metaMsg, defaultMessage: metaMsg })}
      </BetaVersionNotice>
      <BodyContainer maxWidth={isWarrantyLetterPage ? "100%" : maxWidth}>
        {children}
      </BodyContainer>
    </>
  );
};

export default Body;
