import dayjs from "dayjs";
import "dayjs/locale/de";
import "dayjs/locale/fr";
import localizedFormat from "dayjs/plugin/localizedFormat";

const dayjsExtended = (locale: string) => {
  dayjs.extend(localizedFormat);
  dayjs.locale(locale);
  return dayjs;
};

export default dayjsExtended;
