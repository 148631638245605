import pitchedRoof from "../assets/pitchedRoof.svg";
import flatRoof from "../assets/flatRoof.svg";
import otherRoof from "../assets/otherRoof.svg";

import coldRoofPng_new from "../assets/images/coldRoof_new.png";
import flatRoofPng_new from "../assets/images/flatRoof_new.png";
import notVentilatedPng from "../assets/images/notVentilated.png";

import coldRoof_new from "../assets/coldRoof_new.svg";
import flatRoof_new from "../assets/flatRoof_new.svg";
import notVentilated from "../assets/notVentilated.svg";

import pitched_roof_open from "../assets/images/pitched_roof_open.svg";
import pitched_roof_tight from "../assets/images/pitched_roof_tight.svg";

import pitched_roof_open_png from "../assets/images/pitched_roof_open.png";
import pitched_roof_tight_png from "../assets/images/pitched_roof_tight.png";

import sigaRoof from "../assets/sigaRoof.svg";

import sigaPitchedRoof from "../assets/sigaPitchedRoof.svg";

import vcl from "../assets/images/vcl.svg";

export enum BUTTON_TYPE {
  "VISUAL" = 0,
  "SQUARE" = 1,
  "CHECKBOX" = 2,
  "RADIO" = 3,
}

export enum SECTION_ID {
  "TYPE_OF_CONSTRUCTION" = "type_of_construction",
  "ROOF_TYPE" = "roof_type",
  "FLAT_ROOF_BUILD_UP" = "flat_roof_build_up",
  "INSULATION_BETWEEN_RAFTERS" = "insulation_between_rafters",
  "INSULATION_LAYER_THICKNESS" = "insulation_layer_thickness",
  "WOODEN_SUPPORT_PANEL" = "wooden_support_panel",
  "WOOD_SUBSTRATE" = "wood_substrate",
  "WATERPROOFING" = "waterproofing",
  "COLOUR_WATERPROOFING" = "colour_waterproofing",
  "SHADE" = "shade",
  "VAPOR_CONTROL_LAYER" = "vapor_control_layer",
  "EXTERNAL_OVER_INSULATION" = "external_over_insulation",

  "PITCHED_ROOF_BUILD_UP" = "pitched_roof_build_up",
  "PITCHED_ROOF_MEMBRANE_SIDE" = "pitched_roof_membrane_side",
  "PITCHED_ROOF_INSULATION_BETWEEN_RAFTERS" = "pitched_roof_insulation_between_rafters",
  "PITCHED_ROOF_MATERIAL_PROPERTIES" = "pitched_roof_material_properties",
  "PITCHED_ROOF_SEALING_TYPE" = "pitched_roof_sealing_type",
  "PITCHED_ROOF_COVERING_MATERIAL" = "pitched_roof_covering_material",
  "PITCHED_ROOF_COVERING_COLOR" = "pitched_roof_covering_color",
  "PITCHED_ROOF_SHADE_CAUSED_BY" = "pitched_roof_shade_caused_by",
  "PITCHED_ROOF_VAPOR_CONTROL_LAYER" = "pitched_roof_vapor_control_layer",
  "PITCHED_ROOF_ALIGNMENT" = "pitched_roof_alignment",

  "FORM" = "form",
}

export enum API {
  "FLAT_ROOF" = "flat_roof_api",
  "PITCHED_ROOF" = "pitched_roof_api",
}

export const notSupportedMessage =
  "Currently we do not support this configuration. For more information please consult the PDF form.";

export const roofNotSupported = "Nachweisfrei unter Einhaltung der a.a.R.d.T..";
export const roofNotSupportedNewMessage =
  "Nachweißfrei unter Einhaltung der allgemein anerkannten Regeln der Technik (a.a.R.d.T.) und unter Beachtung des Mindestwärmeschutz.\n{link}SIGA-Bahnen in verschiedenen Konstruktionen{linkEnd}";

export const customPdfForm = {
  en: "KM13825_SIGA_Bahnen_verschiedenen_Konstruktionen_Support_App_de.pdf",
  de: "KM13825_SIGA_Bahnen_verschiedenen_Konstruktionen_Support_App_de.pdf",
  fr: "KM13825_SIGA_Bahnen_verschiedenen_Konstruktionen_Support_App_de.pdf",
};

export interface WarningMessage {
  message: string;
  rule: string;
  pdf?: { [key: string]: string };
  includeInLetter?: boolean;
}

export interface DataNode {
  id: string;
  title: string;
  parent?: string;
  end?: boolean;
  selected?: boolean;
  buttonType?: BUTTON_TYPE;
  freeType?: boolean;
  childNodes?: DataNode[];
  icon?: string;
  iconPng?: string;
  hoverElementPadding?: string;
  tooltip?: string;
  value: string;
  selectValue?: string;
  disabled?: boolean;
  skipSteps?: number[];
  nextSection?: SECTION_ID;
  disableRules?: {
    sectionId: SECTION_ID;
    nodeId: string;
  }[];
}

export interface DataSection {
  id: SECTION_ID;
  divider?: boolean;
  api?: API;
  paramsFetched?: boolean;
  title?: string;
  nr?: number;
  nodes: DataNode[];
  nextSection?: SECTION_ID;
  warning?: WarningMessage[];
  visualNode?: {
    img: string;
    imgTop?: string;
    label: string;
    top: string;
    left: string;
  };
  highlightNode?: {
    top: string;
    left: string;
    width: string;
    height: string;
  };
  multipleChoice?: boolean;
  mustSelectAll?: boolean;
  sequential?: boolean;
  coverImage?: string;
}

export interface FormField {
  label: string;
  placeholder: string;
  id: string;
  value: string;
  required: boolean;
  type: string;
  disabled?: boolean;
}

export interface SubmitButton {
  title: string;
}

export interface FormData {
  sections: { from: number; to?: number; sectionTitle?: string }[];
  fields: FormField[];
  submitButton: SubmitButton;
}

export const MainData: DataSection[] = [
  {
    id: SECTION_ID.TYPE_OF_CONSTRUCTION,
    title: "Type of construction:",
    divider: false,
    nodes: [
      {
        id: "2_1",
        title: "New construction",
        value: "New construction",
        nextSection: SECTION_ID.ROOF_TYPE,
      },
      {
        id: "2_2",
        title: "Renovation",
        value: "Renovation",
        nextSection: SECTION_ID.ROOF_TYPE,
      },
    ],
  },
  {
    id: SECTION_ID.ROOF_TYPE,
    divider: true,
    title: "Type of roof:",
    nodes: [
      {
        id: "3_1",
        title: "Flat roof (<5°)",
        value: "Flat roof (<5°)",
        icon: flatRoof,
        nextSection: SECTION_ID.FLAT_ROOF_BUILD_UP,
      },
      {
        id: "3_2",
        title: "Pitched roof (>5°)",
        value: "Pitched roof (>5°)",
        icon: pitchedRoof,
        nextSection: SECTION_ID.PITCHED_ROOF_MEMBRANE_SIDE,
      },
      {
        id: "3_3",
        title: "Other",
        value: "Other",
        icon: otherRoof,
      },
    ],
    warning: [
      {
        message: notSupportedMessage,
        rule: "3_3",
      },
    ],
  },
  {
    id: SECTION_ID.PITCHED_ROOF_MEMBRANE_SIDE,
    title:
      "From which side is the vapor barrier / airtightness membrane installed?",
    divider: true,
    nodes: [
      {
        id: "pr_3_1",
        title: "From the inside",
        value: "From the inside",
        nextSection: SECTION_ID.PITCHED_ROOF_BUILD_UP,
      },
      {
        id: "pr_3_2",
        title: "From the outside",
        value: "From the outside",
        // nextSection: SECTION_ID.PITCHED_ROOF_BUILD_UP,
        disabled: true,
      },
    ],
  },
  {
    id: SECTION_ID.PITCHED_ROOF_BUILD_UP,
    divider: true,
    title: "Roof build-up:",
    nodes: [
      {
        id: "pr_4_1",
        title:
          "Diffusion-tight with wood layer (Sd >20 m) / diffusion-open with wood layer",
        value:
          "Diffusion-tight with wood layer (Sd >20 m) / diffusion-open with wood layer",
        buttonType: BUTTON_TYPE.VISUAL,
        icon: pitched_roof_tight,
        iconPng: pitched_roof_tight_png,
        // hoverElementPadding: "0px",
        nextSection: SECTION_ID.PITCHED_ROOF_INSULATION_BETWEEN_RAFTERS,
      },
      {
        id: "pr_4_2",
        title: "Open to diffusion with/without installation underlay",
        value: "Open to diffusion with/without installation underlay",
        buttonType: BUTTON_TYPE.VISUAL,
        icon: pitched_roof_open,
        // hoverElementPadding: "0px",
        iconPng: pitched_roof_open_png,
        end: true,
      },
    ],
    warning: [
      {
        message: notSupportedMessage,
        rule: "pr_4_2",
      },
    ],
  },
  {
    id: SECTION_ID.FLAT_ROOF_BUILD_UP,
    divider: true,
    title: "Roof build-up:",
    nodes: [
      // {
      //   id: "4_1",
      //   title: "Warm roof with Insulation above the deck only",
      //   value: "Warm roof with Insulation above the deck only",
      //   buttonType: BUTTON_TYPE.VISUAL,
      //   icon: warmRoof,
      //   iconPng: warmRoofPng,
      //   disabled: true,
      // },
      {
        id: "4_3",
        title: "Flat roof with insulation above and below the deck",
        value: "Flat roof with insulation above and below the deck",
        buttonType: BUTTON_TYPE.VISUAL,
        icon: flatRoof_new,
        iconPng: flatRoofPng_new,
        nextSection: SECTION_ID.INSULATION_BETWEEN_RAFTERS,
      },
      {
        id: "4_2",
        title: "Cold roof with active ventilation",
        value: "Cold roof with active ventilation",
        buttonType: BUTTON_TYPE.VISUAL,
        icon: coldRoof_new,
        iconPng: coldRoofPng_new,
        end: true,
      },
      {
        id: "4_1",
        title: "Not ventilated with on-roof insulation",
        value: "Not ventilated with on-roof insulation",
        buttonType: BUTTON_TYPE.VISUAL,
        icon: notVentilated,
        iconPng: notVentilatedPng,
        end: true,
      },
    ],
    warning: [
      {
        message: roofNotSupportedNewMessage,
        rule: "4_2",
        pdf: customPdfForm,
      },
      {
        message: roofNotSupportedNewMessage,
        rule: "4_1",
        pdf: customPdfForm,
      },
    ],
  },
  {
    id: SECTION_ID.INSULATION_BETWEEN_RAFTERS,
    nr: 1,
    visualNode: {
      img: sigaRoof,
      label: "1",
      top: "90px",
      left: "-40px",
    },
    highlightNode: {
      top: "47px",
      left: "",
      width: "100%",
      height: "118px",
    },
    divider: true,
    title: "Type of the insulation between the rafters",
    nodes: [
      {
        id: "5_1",
        title: "Cellulose",
        value: "Cellulose",
        nextSection: SECTION_ID.INSULATION_LAYER_THICKNESS,
      },
      {
        id: "5_2",
        title: "Mineral wool",
        value: "Mineral wool",
        nextSection: SECTION_ID.INSULATION_LAYER_THICKNESS,
      },
      {
        id: "5_3",
        title: "Wood Fibre",
        value: "Wood Fibre",
        nextSection: SECTION_ID.INSULATION_LAYER_THICKNESS,
      },
      { id: "5_5", title: "Other", value: "Other", end: true },
      {
        id: "5_6",
        title: "Hemp / sheep wool",
        value: "Hemp / sheep wool",
        nextSection: SECTION_ID.INSULATION_LAYER_THICKNESS,
      },
    ],
    warning: [
      {
        message: notSupportedMessage,
        rule: "5_5",
      },
    ],
  },
  {
    id: SECTION_ID.INSULATION_LAYER_THICKNESS,
    nextSection: SECTION_ID.WOODEN_SUPPORT_PANEL,
    highlightNode: {
      top: "47px",
      left: "",
      width: "100%",
      height: "118px",
    },
    divider: true,
    // nr: 1,
    visualNode: {
      img: sigaRoof,
      label: "1",
      top: "90px",
      left: "-40px",
    },
    multipleChoice: true,
    mustSelectAll: true,
    nodes: [
      {
        id: "6_1",
        title: "Insulation layer thickness (mm):",
        buttonType: BUTTON_TYPE.CHECKBOX,
        value: "",
        childNodes: [
          {
            id: "6_1_1",
            title: "120 - 139",
            value: "120 - 139",
            parent: "6_1",
          },
          {
            id: "6_1_2",
            title: "140 - 159",
            value: "140 - 159",
            parent: "6_1",
          },
          {
            id: "6_1_3",
            title: "160 - 179",
            value: "160 - 179",
            parent: "6_1",
          },
          {
            id: "6_1_4",
            title: "180 - 199",
            value: "180 - 199",
            parent: "6_1",
          },
          {
            id: "6_1_5",
            title: "200 - 219",
            value: "200 - 219",
            parent: "6_1",
          },
          {
            id: "6_1_6",
            title: "220 - 239",
            value: "220 - 239",
            parent: "6_1",
          },
          {
            id: "6_1_7",
            title: "240 - 259",
            value: "240 - 259",
            parent: "6_1",
          },
          {
            id: "6_1_8",
            title: "260 - 279",
            value: "260 - 279",
            parent: "6_1",
          },
          {
            id: "6_1_9",
            title: "280 - 300",
            value: "280 - 300",
            parent: "6_1",
          },
          {
            id: "6_1_10",
            title: "other",
            value: "other",
            parent: "6_1",
            end: true,
          },
        ],
      },
      {
        id: "6_2",
        title: "Lambda value λ(w/mK):",
        buttonType: BUTTON_TYPE.CHECKBOX,
        value: "",
        childNodes: [
          {
            id: "6_2_1",
            title: "0.041 - 0.045",
            value: "0.045",
            parent: "6_2",
          },
          {
            id: "6_2_2",
            title: "0.036 - 0.040",
            value: "0.040",
            parent: "6_2",
          },
          {
            id: "6_2_3",
            title: "0.031 - 0.035",
            value: "0.035",
            parent: "6_2",
          },
          {
            id: "6_2_4",
            title: "0.025 - 0.030",
            value: "0.032",
            parent: "6_2",
          },
        ],
      },
    ],
    warning: [
      {
        message: notSupportedMessage,
        rule: "6_1_10",
      },
    ],
  },
  {
    title: "Is there a wooden support panel for the over-insulation?",
    id: SECTION_ID.WOODEN_SUPPORT_PANEL,
    warning: [
      {
        message:
          "Important: We recomend to install Bitum membran (SD value bigger than 100m) on the to protect wood susbrstate membrane.",
        rule: "7_1",
        includeInLetter: true,
      },
      {
        message: notSupportedMessage,
        rule: "7_2",
      },
    ],
    divider: true,
    nr: 2,
    visualNode: {
      img: sigaRoof,
      label: "2",
      top: "25px",
      left: "calc(100% - 40px)",
    },
    highlightNode: {
      top: "34px",
      left: "",
      width: "100%",
      height: "14px",
    },
    nodes: [
      {
        id: "7_1",
        title: "Yes",
        value: "Yes",
        buttonType: BUTTON_TYPE.SQUARE,
        nextSection: SECTION_ID.WOOD_SUBSTRATE,
      },
      {
        id: "7_2",
        title: "No",
        value: "No",
        buttonType: BUTTON_TYPE.SQUARE,
        end: true,
      },
    ],
  },
  {
    id: SECTION_ID.WOOD_SUBSTRATE,
    title: "What is the type of wood substrate?",
    highlightNode: {
      top: "34px",
      left: "",
      width: "100%",
      height: "14px",
    },
    visualNode: {
      img: sigaRoof,
      label: "2",
      top: "25px",
      left: "calc(100% - 40px)",
    },
    nodes: [
      {
        id: "8_1",
        title: "OSB",
        value: "OSB",
        nextSection: SECTION_ID.WATERPROOFING,
      },
      {
        id: "8_2",
        title: "Wooden formwork",
        value: "Wooden formwork",
        nextSection: SECTION_ID.WATERPROOFING,
      },
      {
        id: "8_3",
        title: "3 layer plate",
        value: "3 layer plate",
        nextSection: SECTION_ID.WATERPROOFING,
      },
      // {
      //   id: "8_4",
      //   title: "Other",
      //   value: "Other",
      //   buttonType: BUTTON_TYPE.RADIO,
      //   freeType: true,
      // },
    ],
    divider: true,
  },
  {
    id: SECTION_ID.WATERPROOFING,
    title: "What is the type of waterproofing?",
    nodes: [
      {
        id: "8_1_1",
        title: "EPDM",
        value: "EPDM",
        nextSection: SECTION_ID.COLOUR_WATERPROOFING,
      },
      {
        id: "8_1_2",
        title: "PVC / EVA",
        value: "PVC / EVA",
        nextSection: SECTION_ID.COLOUR_WATERPROOFING,
      },
      {
        id: "8_1_3",
        title: "Bitumen",
        value: "Bitumen",
        nextSection: SECTION_ID.COLOUR_WATERPROOFING,
      },
    ],
    nr: 3,
    divider: true,
    visualNode: {
      img: sigaRoof,
      label: "3",
      top: "-13px",
      left: "calc(50% - 20px)",
    },
    highlightNode: {
      top: "0px",
      left: "",
      width: "100%",
      height: "10px",
    },
  },
  {
    id: SECTION_ID.COLOUR_WATERPROOFING,
    title: "Colour of the waterproofing membrane:",
    nr: 3,
    divider: true,
    visualNode: {
      img: sigaRoof,
      label: "3",
      top: "-13px",
      left: "calc(50% - 20px)",
    },
    highlightNode: {
      top: "0px",
      left: "",
      width: "100%",
      height: "10px",
    },
    nodes: [
      {
        id: "'10_1",
        title: "Dark (Black, anthracite)",
        value: "Dark (Black, anthracite)",
        nextSection: SECTION_ID.SHADE,
      },
      {
        id: "'10_2",
        title: "Middle (Grey)",
        value: "Middle (Grey)",
        nextSection: SECTION_ID.SHADE,
      },
      {
        id: "'10_3",
        title: "Light (White, beige)",
        value: "Light (White, beige)",
        nextSection: SECTION_ID.SHADE,
      },
      {
        id: "'10_4",
        title: "Green, red & brown",
        value: "Green, red & brown",
        nextSection: SECTION_ID.SHADE,
      },
    ],
  },
  {
    id: SECTION_ID.SHADE,
    title: "Shade is caused by",
    visualNode: {
      img: sigaRoof,
      label: "3",
      top: "-13px",
      left: "calc(50% - 20px)",
    },
    highlightNode: {
      top: "0px",
      left: "",
      width: "100%",
      height: "10px",
    },
    divider: false,
    nodes: [
      {
        id: "12_1",
        title: "Solar panel",
        value: "Solar panel",
        nextSection: SECTION_ID.VAPOR_CONTROL_LAYER,
      },
      {
        id: "12_2",
        title: "Terrace (wood or stone)",
        value: "Terrace (wood or stone)",
        nextSection: SECTION_ID.VAPOR_CONTROL_LAYER,
      },
      {
        id: "12_3",
        title: "Vegetation (0 - 100 mm)",
        value: "Vegetation (0 - 100 mm)",
        nextSection: SECTION_ID.VAPOR_CONTROL_LAYER,
      },
      {
        id: "12_4",
        title: "Pebbles (max. 120 mm)",
        value: "Pebbles (max. 120 mm)",
        nextSection: SECTION_ID.VAPOR_CONTROL_LAYER,
      },
      {
        id: "12_5",
        title: "Building",
        value: "Building",
        nextSection: SECTION_ID.VAPOR_CONTROL_LAYER,
      },
      {
        id: "12_6",
        title: "No shade",
        value: "No shade",
        nextSection: SECTION_ID.VAPOR_CONTROL_LAYER,
      },
    ],
  },
  {
    id: SECTION_ID.VAPOR_CONTROL_LAYER,
    title: "Which vapour control layer (VCL) do you want to use?",
    nr: 4,
    divider: true,
    visualNode: {
      img: sigaRoof,
      label: "4",
      top: "155px",
      left: "-45px",
    },
    highlightNode: {
      top: "165px",
      left: "0px",
      width: "100%",
      height: "11px",
    },
    coverImage: vcl,
    nodes: [
      {
        id: "1_2",
        title: "Majrex",
        value: "Majrex",
        nextSection: SECTION_ID.EXTERNAL_OVER_INSULATION,
      },
      {
        id: "1_1",
        title: "Majpell 5 / Majpell 5 R",
        value: "Majpell 5 / Majpell 5 R",
        nextSection: SECTION_ID.EXTERNAL_OVER_INSULATION,
      },
    ],
    warning: [
      {
        message:
          "In addition to the tried-and-tested SIGA adhesive products, the use of the Majrex 200 vapor control layer offers you even greater security.<br></br> With its hygrobrid technology and even more security, it fulfills the desire of many tradespeople to get everything from a single source. A large number of different applications can be covered with the universally applicable and easy-to-use vapor control layer.",
        rule: "1_1",
      },
    ],
  },
  {
    id: SECTION_ID.EXTERNAL_OVER_INSULATION,
    title: "External over-insulation (bio-based insulation is excluded):",
    nr: 5,
    api: API.FLAT_ROOF,
    paramsFetched: false,
    visualNode: {
      img: sigaRoof,
      label: "5",
      top: "0px",
      left: "-40px",
    },
    highlightNode: {
      top: "6px",
      left: "",
      width: "100%",
      height: "20px",
    },
    divider: true,
    multipleChoice: true,
    mustSelectAll: true,
    sequential: true,
    nextSection: SECTION_ID.FORM,
    nodes: [
      {
        id: "9_1",
        title: "Minimum insulation thickness in the layer (mm):",
        buttonType: BUTTON_TYPE.CHECKBOX,
        value: "",
        childNodes: [
          { id: "9_1_1", value: "40mm", title: "40mm", parent: "9_1" },
          { id: "9_1_2", value: "60 mm", title: "60 mm", parent: "9_1" },
          {
            id: "9_1_3",
            value: "80 mm",
            title: "80 mm",
            parent: "9_1",
          },
          { id: "9_1_4", value: "100mm", title: "100mm", parent: "9_1" },
          { id: "9_1_5", value: "120", title: "120", parent: "9_1" },
          { id: "9_1_6", value: ">120", title: ">120", parent: "9_1" },
        ],
      },
      {
        id: "9_2",
        title: "Lambda value λ(w/mK):",
        disabled: true,
        buttonType: BUTTON_TYPE.CHECKBOX,
        value: "",
        childNodes: [
          {
            id: "9_2_1",
            title: "0.036 - 0.040",
            value: "0.036 - 0.040",
            parent: "9_2",
          },
          {
            id: "9_2_2",
            title: "0.031 - 0.035",
            value: "0.031 - 0.035",
            parent: "9_2",
          },
          {
            id: "9_2_3",
            title: "0.026 - 0.030",
            value: "0.026 - 0.030",
            parent: "9_2",
          },
          {
            id: "9_2_4",
            title: "0.025 or less",
            value: "0.025 or less",
            parent: "9_2",
          },
        ],
      },
    ],
  },
  // ------------------ PITCHED ROOF ------------------
  {
    id: SECTION_ID.PITCHED_ROOF_INSULATION_BETWEEN_RAFTERS,
    title:
      "Type of insulation material between the rafters (full rafter insulation):",
    divider: true,
    highlightNode: {
      top: "90px",
      left: "",
      width: "100%",
      height: "152px",
    },
    nr: 1,
    visualNode: {
      img: sigaPitchedRoof,
      label: "1",
      top: "140px",
      left: "120px",
      imgTop: "10%",
    },
    nodes: [
      {
        id: "pr_5_1",
        title: "Cellulose",
        value: "Cellulose",
        nextSection: SECTION_ID.PITCHED_ROOF_MATERIAL_PROPERTIES,
      },
      {
        id: "pr_5_2",
        title: "Mineral wool",
        value: "Mineral wool",
        nextSection: SECTION_ID.PITCHED_ROOF_MATERIAL_PROPERTIES,
      },
      {
        id: "pr_5_3",
        title: "Wood Fibre",
        value: "Wood Fibre",
        nextSection: SECTION_ID.PITCHED_ROOF_MATERIAL_PROPERTIES,
      },
      { id: "pr_5_4", title: "Other", value: "Other", end: true },
    ],
    warning: [
      {
        message: notSupportedMessage,
        rule: "pr_5_4",
      },
    ],
  },

  {
    id: SECTION_ID.PITCHED_ROOF_MATERIAL_PROPERTIES,
    title:
      "What material properties does the intermediate rafter insulation have?",
    // nextSection: SECTION_ID.WOODEN_SUPPORT_PANEL,
    highlightNode: {
      top: "90px",
      left: "",
      width: "100%",
      height: "152px",
    },
    nr: 1,
    visualNode: {
      img: sigaPitchedRoof,
      label: "1",
      top: "140px",
      left: "120px",
      imgTop: "10%",
    },
    multipleChoice: true,
    mustSelectAll: true,
    nextSection: SECTION_ID.PITCHED_ROOF_SEALING_TYPE,
    nodes: [
      {
        id: "pr_6_1",
        title: "Insulation layer thickness (mm):",
        buttonType: BUTTON_TYPE.CHECKBOX,
        value: "",
        childNodes: [
          {
            id: "pr_6_1_1",
            title: "120 - 139",
            value: "120 - 139",
            parent: "pr_6_1",
          },
          {
            id: "pr_6_1_2",
            title: "140 - 159",
            value: "140 - 159",
            parent: "pr_6_1",
          },
          {
            id: "pr_6_1_3",
            title: "160 - 179",
            value: "160 - 179",
            parent: "pr_6_1",
          },
          {
            id: "pr_6_1_4",
            title: "180 - 199",
            value: "180 - 199",
            parent: "pr_6_1",
          },
          {
            id: "pr_6_1_5",
            title: "200 - 219",
            value: "200 - 219",
            parent: "pr_6_1",
          },
          {
            id: "pr_6_1_6",
            title: "220 - 239",
            value: "220 - 239",
            parent: "pr_6_1",
          },
          {
            id: "pr_6_1_7",
            title: "240 - 259",
            value: "240 - 259",
            parent: "pr_6_1",
          },
          {
            id: "pr_6_1_8",
            title: "260 - 279",
            value: "260 - 279",
            parent: "pr_6_1",
          },
          {
            id: "pr_6_1_9",
            title: "280 - 300",
            value: "280 - 300",
            parent: "pr_6_1",
          },
          {
            id: "pr_6_1_10",
            title: "other",
            value: "other",
            parent: "pr_6_1",
            end: true,
          },
        ],
      },
      {
        id: "pr_6_2",
        title: "Lambda value λ(w/mK):",
        buttonType: BUTTON_TYPE.CHECKBOX,
        value: "",
        childNodes: [
          {
            id: "pr_6_2_2",
            title: "0.036 - 0.040",
            value: "0.040",
            parent: "pr_6_2",
          },
          {
            id: "pr_6_2_3",
            title: "0.031 - 0.035",
            value: "0.035",
            parent: "pr_6_2",
          },
          {
            id: "pr_6_2_4",
            title: "0.025 - 0.030",
            value: "0.032",
            parent: "pr_6_2",
          },
        ],
      },
    ],
    warning: [
      {
        message: notSupportedMessage,
        rule: "pr_6_1_10",
      },
    ],
  },
  {
    id: SECTION_ID.PITCHED_ROOF_SEALING_TYPE,
    nr: 2,
    highlightNode: {
      top: "74px",
      left: "",
      width: "100%",
      height: "14px",
    },
    visualNode: {
      img: sigaPitchedRoof,
      label: "2",
      top: "60px",
      left: "140px",
      imgTop: "10%",
    },
    title: "What type of sealing is above the wooden formwork?",
    divider: true,
    nodes: [
      {
        id: "pr_7_1",
        title: "Bitumen",
        value: "Bitumen",
        nextSection: SECTION_ID.PITCHED_ROOF_COVERING_MATERIAL,
      },
      {
        id: "pr_7_2",
        title: "Diffusible undercover",
        value: "Diffusible undercover",
        nextSection: SECTION_ID.PITCHED_ROOF_COVERING_MATERIAL,
      },
      {
        id: "pr_7_3",
        title: "Other",
        value: "Other",
        end: true,
      },
    ],
    warning: [
      {
        message: notSupportedMessage,
        rule: "pr_7_3",
      },
    ],
  },
  {
    id: SECTION_ID.PITCHED_ROOF_COVERING_MATERIAL,
    title: "Which covering material is used?",
    nr: 3,
    highlightNode: {
      top: "0px",
      left: "",
      width: "100%",
      height: "47px",
    },
    visualNode: {
      img: sigaPitchedRoof,
      label: "3",
      top: "0px",
      left: "220px",
      imgTop: "10%",
    },
    divider: true,
    nodes: [
      {
        id: "pr_8_1",
        title: "Brick/concrete roof tiles",
        value: "Brick/concrete roof tiles",
        nextSection: SECTION_ID.PITCHED_ROOF_COVERING_COLOR,
      },
      {
        id: "pr_8_2",
        title: "Metal roofing",
        value: "Metal roofing",
        nextSection: SECTION_ID.PITCHED_ROOF_COVERING_COLOR,
      },
      {
        id: "pr_8_3",
        title: "Slate",
        value: "Slate",
        nextSection: SECTION_ID.PITCHED_ROOF_COVERING_COLOR,
      },
      {
        id: "pr_8_4",
        title: "No additional covering (bare roof)",
        value: "No additional covering (bare roof)",
        nextSection: SECTION_ID.PITCHED_ROOF_COVERING_COLOR,
      },
      {
        id: "pr_8_5",
        title: "Bitumen membrane / shingles",
        value: "Bitumen membrane / shingles",
        nextSection: SECTION_ID.PITCHED_ROOF_COVERING_COLOR,
      },
      {
        id: "pr_8_6",
        title: "Other",
        value: "Other",
        end: true,
      },
    ],
    warning: [
      {
        message: notSupportedMessage,
        rule: "pr_8_6",
      },
    ],
  },
  {
    id: SECTION_ID.PITCHED_ROOF_COVERING_COLOR,
    title: "Color of the covering?",
    highlightNode: {
      top: "0px",
      left: "",
      width: "100%",
      height: "47px",
    },
    visualNode: {
      img: sigaPitchedRoof,
      label: "3",
      top: "0px",
      left: "220px",
      imgTop: "10%",
    },
    nodes: [
      {
        id: "pr_9_1",
        title: "Dark (Black, anthracite)",
        value: "Dark (Black, anthracite)",
        nextSection: SECTION_ID.PITCHED_ROOF_SHADE_CAUSED_BY,
      },
      {
        id: "pr_9_2",
        title: "Light (White, beige)",
        value: "Light (White, beige)",
        nextSection: SECTION_ID.PITCHED_ROOF_SHADE_CAUSED_BY,
        disableRules: [
          {
            sectionId: SECTION_ID.PITCHED_ROOF_COVERING_MATERIAL,
            nodeId: "pr_8_3",
          },
          {
            sectionId: SECTION_ID.PITCHED_ROOF_COVERING_MATERIAL,
            nodeId: "pr_8_5",
          },
        ],
      },
      {
        id: "pr_9_3",
        title: "Green, red & brown",
        value: "Green, red & brown",
        nextSection: SECTION_ID.PITCHED_ROOF_SHADE_CAUSED_BY,
      },
    ],
  },
  {
    id: SECTION_ID.PITCHED_ROOF_SHADE_CAUSED_BY,
    title: "Shade is caused by",
    highlightNode: {
      top: "0px",
      left: "",
      width: "100%",
      height: "47px",
    },
    visualNode: {
      img: sigaPitchedRoof,
      label: "3",
      top: "0px",
      left: "220px",
      imgTop: "10%",
    },
    nodes: [
      {
        id: "pr_10_1",
        title: "Solar panel",
        value: "Solar panel",
        nextSection: SECTION_ID.PITCHED_ROOF_VAPOR_CONTROL_LAYER,
      },
      {
        id: "pr_10_2",
        title: "Buildings / Trees",
        value: "Buildings / Trees",
        nextSection: SECTION_ID.PITCHED_ROOF_VAPOR_CONTROL_LAYER,
      },
      {
        id: "pr_10_3",
        title: "No shade",
        value: "No shade",
        nextSection: SECTION_ID.PITCHED_ROOF_VAPOR_CONTROL_LAYER,
      },
    ],
  },
  {
    id: SECTION_ID.PITCHED_ROOF_VAPOR_CONTROL_LAYER,
    title: "Which vapour control layer (VCL) do you want to use?",
    nr: 4,
    divider: true,
    // highlightNode: {
    //   top: "240px",
    //   left: "0px",
    //   width: "100%",
    //   height: "11px",
    // },
    visualNode: {
      img: sigaPitchedRoof,
      label: "4",
      top: "230px",
      left: "240px",
      imgTop: "10%",
    },
    nodes: [
      {
        id: "pr_11_1",
        title: "Majrex",
        value: "Majrex",
        nextSection: SECTION_ID.PITCHED_ROOF_ALIGNMENT,
      },
      {
        id: "pr_11_2",
        title: "Majpell 5 / Majpell 5 R",
        value: "Majpell 5 / Majpell 5 R",
        nextSection: SECTION_ID.PITCHED_ROOF_ALIGNMENT,
      },
    ],
    warning: [
      {
        message:
          "In addition to the tried-and-tested SIGA adhesive products, the use of the Majrex 200 vapor control layer offers you even greater security.<br></br> With its hygrobrid technology and even more security, it fulfills the desire of many tradespeople to get everything from a single source. A large number of different applications can be covered with the universally applicable and easy-to-use vapor control layer.",
        rule: "pr_11_2",
      },
    ],
  },
  {
    id: SECTION_ID.PITCHED_ROOF_ALIGNMENT,
    nr: 5,
    highlightNode: {
      top: "240px",
      left: "0px",
      width: "100%",
      height: "11px",
    },
    visualNode: {
      img: sigaPitchedRoof,
      label: "4",
      top: "230px",
      left: "240px",
      imgTop: "10%",
    },
    api: API.PITCHED_ROOF,
    paramsFetched: false,
    // visualNode: {
    //   img: sigaRoof,
    //   label: "5",
    //   top: "0px",
    //   left: "-40px",
    // },
    // highlightNode: {
    //   top: "6px",
    //   left: "",
    //   width: "100%",
    //   height: "20px",
    // },
    divider: true,
    multipleChoice: true,
    mustSelectAll: true,
    sequential: true,
    nextSection: SECTION_ID.FORM,
    nodes: [
      {
        id: "pr_11_1",
        title: "Alignment (worst situation)",
        buttonType: BUTTON_TYPE.CHECKBOX,
        value: "",
        childNodes: [
          {
            id: "pr_11_1_1",
            title: "North",
            value: "North",
            parent: "pr_11_1",
          },
          {
            id: "pr_11_1_2",
            title: "East",
            value: "East",
            parent: "pr_11_1",
          },
          {
            id: "pr_11_1_3",
            title: "South",
            value: "South",
            parent: "pr_11_1",
          },
          {
            id: "pr_11_1_4",
            title: "West",
            value: "West",
            parent: "pr_11_1",
          },
        ],
      },
      {
        id: "pr_11_2",
        title: "Roof pitch",
        buttonType: BUTTON_TYPE.CHECKBOX,
        value: "",
        disabled: true,
        childNodes: [
          {
            id: "pr_11_2_1",
            value: "5° - 9°",
            title: "5° - 9°",
            parent: "pr_11_2",
          },
          {
            id: "pr_11_2_2",
            value: "10° - 19°",
            title: "10° - 19°",
            parent: "pr_11_2",
          },
          {
            id: "pr_11_2_3",
            value: "20° - 29°",
            title: "20° - 29°",
            parent: "pr_11_2",
          },
          {
            id: "pr_11_2_4",
            value: "30° - 39°",
            title: "30° - 39°",
            parent: "pr_11_2",
          },
          {
            id: "pr_11_2_5",
            value: "40° - 49°",
            title: "40° - 49°",
            parent: "pr_11_2",
          },
          {
            id: "pr_11_2_6",
            value: "50° - 59°",
            title: "50° - 59°",
            parent: "pr_11_2",
          },
          {
            id: "pr_11_2_7",
            value: "60° - 69°",
            title: "60° - 69°",
            parent: "pr_11_2",
          },
          {
            id: "pr_11_2_8",
            value: "70° - 79°",
            title: "70° - 79°",
            parent: "pr_11_2",
          },
          {
            id: "pr_11_2_9",
            value: "80° - 89°",
            title: "80° - 89°",
            parent: "pr_11_2",
          },
        ],
      },
    ],
  },
];

const formFields: FormField[] = [
  {
    label: "Company",
    placeholder: "Company",
    id: "form_company",
    value: "",
    required: true,
    type: "text",
  },
  {
    label: "Name / First name",
    placeholder: "Name / First name",
    id: "form_name",
    value: "",
    required: true,
    type: "text",
  },
  {
    label: "Street and house number",
    placeholder: "Street and house number",
    id: "form_street",
    value: "",
    required: true,
    type: "text",
  },
  {
    label: "Postal code",
    placeholder: "Postal code",
    id: "form_postal",
    value: "",
    required: true,
    type: "text",
  },
  {
    label: "City",
    placeholder: "City",
    id: "form_city",
    value: "",
    required: true,
    type: "text",
  },
  {
    label: "Country",
    placeholder: "Country",
    id: "form_country",
    value: "DE",
    required: true,
    type: "text",
  },
  {
    label: "Email",
    placeholder: "Email",
    id: "form_email",
    value: "",
    required: true,
    type: "email",
  },
  {
    label: "Phone number",
    placeholder: "Phone number",
    id: "form_phone",
    value: "",
    required: true,
    type: "text",
  },
  {
    label: "SIGA Contact Person's Email",
    placeholder: "SIGA Contact Person's Email",
    id: "form_siga_contact_person",
    value: "",
    required: true,
    type: "email",
  },
  // ------ contstruction site data
  {
    label: "Construction site name",
    placeholder: "Construction site name",
    id: "form_construction_site_data_name",
    value: "",
    required: true,
    type: "text",
  },
  {
    label: "Builder",
    placeholder: "Builder",
    id: "form_construction_site_data_builder",
    value: "",
    required: false,
    type: "text",
  },
  {
    label: "Street and house number",
    placeholder: "Street and house number",
    id: "form_construction_site_data_street",
    value: "",
    required: true,
    type: "text",
  },
  {
    label: "Postal code",
    placeholder: "Postal code",
    id: "form_construction_site_data_postal",
    value: "",
    required: true,
    type: "text",
  },
  {
    label: "City",
    placeholder: "City",
    id: "form_construction_site_data_city",
    value: "",
    required: true,
    type: "text",
  },
  {
    label: "Country",
    placeholder: "Country",
    id: "form_construction_site_data_country",
    value: "DE",
    required: true,
    disabled: true,
    type: "text",
  },
];

export const formData: FormData = {
  sections: [
    { from: 0, to: 9 },
    {
      sectionTitle: "Construction site address",
      from: 9,
    },
  ],
  fields: formFields,
  submitButton: {
    title: "Continue",
  },
};

export const letterOrder = {
  [API.FLAT_ROOF]: [
    SECTION_ID.TYPE_OF_CONSTRUCTION,
    SECTION_ID.ROOF_TYPE,
    SECTION_ID.FLAT_ROOF_BUILD_UP,
    SECTION_ID.VAPOR_CONTROL_LAYER,
    SECTION_ID.INSULATION_BETWEEN_RAFTERS,
    SECTION_ID.INSULATION_LAYER_THICKNESS,
    SECTION_ID.WOODEN_SUPPORT_PANEL,
    SECTION_ID.WOOD_SUBSTRATE,
    SECTION_ID.EXTERNAL_OVER_INSULATION,
    SECTION_ID.WATERPROOFING,
    SECTION_ID.COLOUR_WATERPROOFING,
    SECTION_ID.SHADE,
  ],
  [API.PITCHED_ROOF]: [
    SECTION_ID.TYPE_OF_CONSTRUCTION,
    SECTION_ID.ROOF_TYPE,
    SECTION_ID.PITCHED_ROOF_BUILD_UP,
    SECTION_ID.PITCHED_ROOF_VAPOR_CONTROL_LAYER,
    SECTION_ID.PITCHED_ROOF_INSULATION_BETWEEN_RAFTERS,
    SECTION_ID.PITCHED_ROOF_MATERIAL_PROPERTIES,
    SECTION_ID.PITCHED_ROOF_SEALING_TYPE,
    SECTION_ID.PITCHED_ROOF_COVERING_MATERIAL,
    SECTION_ID.PITCHED_ROOF_COVERING_COLOR,
    SECTION_ID.PITCHED_ROOF_SHADE_CAUSED_BY,
    SECTION_ID.PITCHED_ROOF_ALIGNMENT,
  ],
};
