import { BodySide, Steps, Title } from "../../components";
import { ImageArea } from "../ImageArea";
import { useElementSize } from "usehooks-ts";
import styled from "styled-components";
import { Chip, styled as muiStyled } from "@mui/material";
import usePassword from "../../hooks/usePassword";
import { TitleRow } from "../TitleRow";
import { FormattedMessage, useIntl } from "react-intl";
import { MEDIA_SMALL } from "../../constants";
import useSticky from "../../hooks/useSticky";
import { useNavigate, useParams } from "react-router-dom";
import { HowToUseButton } from "../../styledComponents";

const BodySideContainer = styled.div`
  display: flex;
  margin-top: 40px;
  margin-bottom: 20px;
  ${MEDIA_SMALL} {
    flex-direction: column-reverse;
  }
`;

const AppContent = () => {
  const [bodySideRef, { width, height }] = useElementSize();
  const hasPassword = usePassword();
  const navigate = useNavigate();
  const { lang } = useParams();
  const intl = useIntl();
  const { sticky, stickyRef } = useSticky();

  return hasPassword ? (
    <>
      <TitleRow
        leftSide={<></>}
        center={
          <Title
            title={
              <FormattedMessage
                id="Digital checklist for Your SIGA product"
                defaultMessage="Digital checklist for Your SIGA product"
              />
            }
          />
        }
        rightSide={
          <HowToUseButton
            onClick={() => navigate(`/${lang}/how-to-use`)}
            color="primary"
            label={intl.formatMessage({
              id: `How to use?`,
              defaultMessage: `How to use?`,
            })}
          />
        }
      />
      <BodySideContainer>
        <BodySide percentage="60%" bodyName="main-area">
          <Steps sticky={sticky} />
        </BodySide>
        <BodySide bodyName="image-area" bodyRef={bodySideRef} percentage="40%">
          <ImageArea
            dimensions={{ width, height }}
            sticky={sticky}
            stickyRef={stickyRef}
          />
        </BodySide>
      </BodySideContainer>
    </>
  ) : (
    <></>
  );
};

export default AppContent;
