import { MAX_WIDTH } from "../../constants";
import usePassword from "../../hooks/usePassword";
import { Footer } from "../Footer";
import { Header } from "../Header";
import { Outlet } from "react-router-dom";
import { Body } from "../Body";

const AppWrapper: React.FC = () => {
  const hasPassword = usePassword();

  return hasPassword ? (
    <>
      <Header maxWidth={`${MAX_WIDTH}px`} />
      <Body maxWidth={`${MAX_WIDTH}px`}>
        <Outlet />
      </Body>
      <Footer maxWidth={`${MAX_WIDTH}px`} />
    </>
  ) : (
    <></>
  );
};
export default AppWrapper;
