import React from "react";
import styled from "styled-components";
import Breadcrumb, { BreadcrumbState } from "./Breadcrumb";
import {
  MEDIA_SMALL,
  MEDIA_SUPER_SMALL,
  SIGA_MAIN_BLUE,
} from "../../constants";

export interface BreadcrumbData {
  title: string;
  theState: BreadcrumbState;
  nr: number;
}

interface BreadcrumbProps {
  breadcrumbs: BreadcrumbData[];
}

const BreadcrumbsContainerComponent = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 40px 0;
  .breadcrumb {
    position: relative;
    &:not(:last-of-type) {
      margin-right: 70px;
      ${MEDIA_SMALL} {
        margin-right: 16px;
      }
      ${MEDIA_SUPER_SMALL} {
        margin-right: 0px;
      }
      &:before {
        position: absolute;
        top: 17px;
        left: 74px;
        content: "";
        width: 80px;
        ${MEDIA_SMALL} {
          width: 28px;
        }
        ${MEDIA_SUPER_SMALL} {
          width: 14px;
        }
        height: 1px;
        display: block;
        background-color: ${SIGA_MAIN_BLUE};
      }
    }
  }
`;

const BreadcrumbsContainer: React.FC<BreadcrumbProps> = ({ breadcrumbs }) => {
  return (
    <BreadcrumbsContainerComponent>
      {breadcrumbs?.map((b, i) => (
        <Breadcrumb key={i} title={b.title} theState={b.theState} nr={b.nr} />
      ))}
    </BreadcrumbsContainerComponent>
  );
};

export default BreadcrumbsContainer;
