import { MainData } from "../data/dummyData";

export const findProduct = (data: MainData) => {
  const productKey = Object.keys(data).find(
    (e: string) =>
      data[parseInt(e, 10)]?.title ===
      "Which vapour control layer (VCL) do you want to use?"
  );
  const productType = data[
    productKey ? parseInt(productKey, 10) : 0
  ].nodes.find((n) => n.selected)?.title;
  return productType === "Majrex" ? "Majrex 200" : "Majpell 5 / Majpell 5 R";
};
