import axios from "axios";
import { PdfFormData } from "../reducers/appData";

const submitForm = async (data: PdfFormData) => {
  return await axios.post(
    `${process.env.REACT_APP_BACKEND_HOST}/v2/submit-form`,
    data
  );
};

export default submitForm;
