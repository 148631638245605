import flatRoofIcon from "../assets/roof.svg";
import flatRoofIconPng from "../assets/images/roof.png";
// import warmRoof from "../assets/warmRoof.svg";
// import warmRoofPng from "../assets/images/warmRoof.png";
import coldRoof from "../assets/coldRoof.svg";
import coldRoofPng from "../assets/images/coldRoof.png";
import pitchedRoof from "../assets/pitchedRoof.svg";
import flatRoof from "../assets/flatRoof.svg";
import otherRoof from "../assets/otherRoof.svg";
import vcl from "../assets/images/vcl.svg";

import coldRoofPng_new from "../assets/images/coldRoof_new.png";
import flatRoofPng_new from "../assets/images/flatRoof_new.png";
import notVentilatedPng from "../assets/images/notVentilated.png";

import coldRoof_new from "../assets/coldRoof_new.svg";
import flatRoof_new from "../assets/flatRoof_new.svg";
import notVentilated from "../assets/notVentilated.svg";
import { SECTION_ID } from "./appData";

export enum BUTTON_TYPE {
  "VISUAL" = 0,
  "SQUARE" = 1,
  "CHECKBOX" = 2,
  "RADIO" = 3,
}

export const languages = ["en", "de", "fr"];

export const notSupportedMessage =
  "Currently we do not support this configuration. For more information please consult the PDF form.";

export const roofNotSupported = "Nachweisfrei unter Einhaltung der a.a.R.d.T..";
export const roofNotSupportedNewMessage =
  "Nachweißfrei unter Einhaltung der allgemein anerkannten Regeln der Technik (a.a.R.d.T.) und unter Beachtung des Mindestwärmeschutz.\n{link}SIGA-Bahnen in verschiedenen Konstruktionen{linkEnd}";

export const pdfForm = {
  en: "SIGA_check_list_for_calculations_and_approvals_SKU-2010enDIV.pdf",
  de: "SIGA_Checkliste_für_Berechnungen_und_Freigaben_SKU-2010deDE.pdf",
  fr: "Checkliste_Berechnungen_fr_fr.pdf",
};

export const customPdfForm = {
  en: "KM13825_SIGA_Bahnen_verschiedenen_Konstruktionen_Support_App_de.pdf",
  de: "KM13825_SIGA_Bahnen_verschiedenen_Konstruktionen_Support_App_de.pdf",
  fr: "KM13825_SIGA_Bahnen_verschiedenen_Konstruktionen_Support_App_de.pdf",
};

export const termsAndConditionsTitle = {
  default: `Allgemeine Geschäftsbedingungen Für Verträge über automatisierte
Gewährleistungsschreiben (Deutschland)`,
  ch: `Allgemeine Geschäftsbedingungen Für Verträge über automatisierte
  Gewährleistungsschreiben (Schweiz)`,
};

export const pdfText1 = `<p>Besten Dank für Ihre Anfrage. Wir haben den von Ihnen
beschriebenen Bauteilaufbau hinsichtlich der Verwendbarkeit von {{p}} beurteilt.</p>`;

export const pdfText2 = `<p>Bei dem von Ihnen unten beschriebenen Konstruktionsaufbau ist das
feuchtetechnische Verhalten beim Einsatz der Dampfbremsbahn {{p}} bei Einhaltung der beschriebenen
Voraussetzungen als unkritisch zu bewerten.</p>`;

export const statementMsg =
  'Based on our calculation with the hygrothermal simulation program (EN 15026) "Delphin - The IBK institute in Dresden / Germany" we can adopt the following statement:\nIn any case, it must be ensured that the minimum thermal insulation requirements are met. This letter refers exclusively to the moisture-related functionality of the described construction. If the general terms and conditions are met,\n for this construction, the use of the vapor barrier is appropriate Majrex SIGA SIGA Cover AG gives you full warranty on all properties guaranteed in the instruction manual for SIGA Majrex.\nApply the SIGA products always according to the SIGA instruction manual. Professional planning, execution and control on-site is required. To ensure a proper hygrothermic function, an accurately assembled airtight-layer is essential.';

export const letterSignature = `Freundliche Grüsse\nIhr SIGA Team`;

export interface DataNode {
  id: string;
  title: string;
  parent?: string;
  notAllowed?: string[];
  end?: boolean;
  selected?: boolean;
  buttonType?: BUTTON_TYPE;
  freeType?: boolean;
  childNodes?: DataNode[];
  icon?: string;
  iconPng?: string;
  tooltip?: string;
  value: string;
  selectValue?: string;
  disabled?: boolean;
  skipSteps?: number[];
}

export interface WarningMessage {
  message: string;
  rule: string;
  pdf?: { [key: string]: string };
  includeInLetter?: boolean;
}

export interface MainData {
  [key: number]: {
    nodes: DataNode[];
    divider?: boolean;
    parents?: DataNode[];
    warning?: WarningMessage[];
    title?: string;
    nr?: number;
    visualNode?: {
      label: string;
      top: string;
      left: string;
    };
    highlightNode?: {
      top: string;
      left: string;
      width: string;
      height: string;
    };
    multipleChoice?: boolean;
    mustSelectAll?: boolean;
    coverImage?: string;
  };
}

export interface FormField {
  label: string;
  placeholder: string;
  id: string;
  value: string;
  required: boolean;
  type: string;
  disabled?: boolean;
}

export interface SubmitButton {
  title: string;
}

export interface FormData {
  sections: { from: number; to?: number; sectionTitle?: string }[];
  fields: FormField[];
  submitButton: SubmitButton;
}

const dummyData: MainData = {
  1: {
    title: "Type of construction:",
    divider: false,
    nodes: [
      {
        id: "2_1",
        title: "New construction",
        value: "New construction",
      },
      {
        id: "2_2",
        title: "Renovation",
        value: "Renovation",
      },
    ],
  },
  2: {
    divider: true,
    title: "Type of roof:",
    nodes: [
      {
        id: "3_1",
        title: "Flat roof (<5°)",
        value: "Flat roof (<5°)",
        icon: flatRoof,
      },
      {
        id: "3_2",
        title: "Pitched roof (>5°)",
        value: "Pitched roof (>5°)",
        icon: pitchedRoof,
        disabled: true,
      },
      {
        notAllowed: ["2_2"],
        id: "3_3",
        title: "Other",
        value: "Other",
        icon: otherRoof,
        end: true,
      },
    ],
    warning: [
      {
        message: notSupportedMessage,
        rule: "3_3",
      },
    ],
  },
  3: {
    divider: true,
    title: "Roof build-up:",
    nodes: [
      // {
      //   id: "4_1",
      //   title: "Warm roof with Insulation above the deck only",
      //   value: "Warm roof with Insulation above the deck only",
      //   buttonType: BUTTON_TYPE.VISUAL,
      //   icon: warmRoof,
      //   iconPng: warmRoofPng,
      //   disabled: true,
      // },
      {
        id: "4_3",
        title: "Flat roof with insulation above and below the deck",
        value: "Flat roof with insulation above and below the deck",
        buttonType: BUTTON_TYPE.VISUAL,
        // icon: flatRoofIcon,
        // iconPng: flatRoofIconPng,
        icon: flatRoof_new,
        iconPng: flatRoofPng_new,
      },
      {
        id: "4_2",
        title: "Cold roof with active ventilation",
        value: "Cold roof with active ventilation",
        buttonType: BUTTON_TYPE.VISUAL,
        // icon: coldRoof,
        // iconPng: coldRoofPng,
        icon: coldRoof_new,
        iconPng: coldRoofPng_new,
        end: true,
      },
      {
        id: "4_1",
        title: "Not ventilated with on-roof insulation",
        value: "Not ventilated with on-roof insulation",
        buttonType: BUTTON_TYPE.VISUAL,
        // icon: coldRoof,
        // iconPng: coldRoofPng,
        icon: notVentilated,
        iconPng: notVentilatedPng,
        end: true,
      },
    ],
    warning: [
      {
        message: roofNotSupportedNewMessage,
        rule: "4_2",
        pdf: customPdfForm,
      },
      {
        message: roofNotSupportedNewMessage,
        rule: "4_1",
        pdf: customPdfForm,
      },
    ],
  },
  4: {
    nr: 1,
    visualNode: {
      label: "1",
      top: "90px",
      left: "-40px",
    },
    highlightNode: {
      top: "47px",
      left: "",
      width: "100%",
      height: "118px",
    },
    divider: true,
    title: "Type of the insulation between the rafters",
    nodes: [
      { id: "5_1", title: "Cellulose", value: "Cellulose" },
      { id: "5_2", title: "Mineral wool", value: "Mineral wool" },
      { id: "5_3", title: "Wood Fibre", value: "Wood Fibre" },
      { id: "5_5", title: "Other", value: "Other", end: true },
      { id: "5_6", title: "Hemp / sheep wool", value: "Hemp / sheep wool" },
    ],
    warning: [
      {
        message: notSupportedMessage,
        rule: "5_5",
      },
      {
        message: notSupportedMessage,
        rule: "5_4",
      },
    ],
  },
  5: {
    highlightNode: {
      top: "47px",
      left: "",
      width: "100%",
      height: "118px",
    },
    divider: true,
    // nr: 1,
    visualNode: {
      label: "1",
      top: "90px",
      left: "-40px",
    },
    multipleChoice: true,
    mustSelectAll: true,
    nodes: [
      {
        id: "6_1",
        title: "Insulation layer thickness (mm):",
        buttonType: BUTTON_TYPE.CHECKBOX,
        value: "",
        childNodes: [
          {
            id: "6_1_1",
            title: "120 - 139",
            value: "120 - 139",
            parent: "6_1",
          },
          {
            id: "6_1_2",
            title: "140 - 159",
            value: "140 - 159",
            parent: "6_1",
          },
          {
            id: "6_1_3",
            title: "160 - 179",
            value: "160 - 179",
            parent: "6_1",
          },
          {
            id: "6_1_4",
            title: "180 - 199",
            value: "180 - 199",
            parent: "6_1",
          },
          {
            id: "6_1_5",
            title: "200 - 219",
            value: "200 - 219",
            parent: "6_1",
          },
          {
            id: "6_1_6",
            title: "220 - 239",
            value: "220 - 239",
            parent: "6_1",
          },
          {
            id: "6_1_7",
            title: "240 - 259",
            value: "240 - 259",
            parent: "6_1",
          },
          {
            id: "6_1_8",
            title: "260 - 279",
            value: "260 - 279",
            parent: "6_1",
          },
          {
            id: "6_1_9",
            title: "280 - 300",
            value: "280 - 300",
            parent: "6_1",
          },
          {
            id: "6_1_10",
            title: "other",
            value: "other",
            parent: "6_1",
            end: true,
          },
        ],
      },
      {
        id: "6_2",
        title: "Lambda value λ(w/mK):",
        buttonType: BUTTON_TYPE.CHECKBOX,
        value: "",
        childNodes: [
          {
            id: "6_2_1",
            title: "0.041 - 0.045",
            value: "0.045",
            parent: "6_2",
          },
          {
            id: "6_2_2",
            title: "0.036 - 0.040",
            value: "0.040",
            parent: "6_2",
          },
          {
            id: "6_2_3",
            title: "0.031 - 0.035",
            value: "0.035",
            parent: "6_2",
          },
          {
            id: "6_2_4",
            title: "0.025 - 0.030",
            value: "0.032",
            parent: "6_2",
          },
        ],
      },
    ],
    warning: [
      {
        message: notSupportedMessage,
        rule: "6_1_10",
      },
    ],
  },
  6: {
    divider: true,
    nr: 2,
    visualNode: {
      label: "2",
      top: "25px",
      left: "calc(100% - 40px)",
    },
    highlightNode: {
      top: "34px",
      left: "",
      width: "100%",
      height: "14px",
    },
    warning: [
      {
        message:
          "Important: We recomend to install Bitum membran (SD value bigger than 100m) on the to protect wood susbrstate membrane.",
        rule: "7_1",
        includeInLetter: true,
      },
      {
        message: notSupportedMessage,
        rule: "7_2",
      },
    ],
    title: "Is there a wooden support panel for the over-insulation?",
    nodes: [
      { id: "7_1", title: "Yes", value: "Yes", buttonType: BUTTON_TYPE.SQUARE },
      {
        id: "7_2",
        title: "No",
        value: "No",
        buttonType: BUTTON_TYPE.SQUARE,
        end: true,
      },
    ],
  },
  7: {
    highlightNode: {
      top: "34px",
      left: "",
      width: "100%",
      height: "14px",
    },
    visualNode: {
      label: "2",
      top: "25px",
      left: "calc(100% - 40px)",
    },
    title: "What is the type of wood substrate?",
    nodes: [
      { id: "8_1", title: "OSB", value: "OSB" },
      {
        id: "8_2",
        title: "Wooden formwork",
        value: "Wooden formwork",
      },
      {
        id: "8_3",
        title: "3 layer plate",
        value: "3 layer plate",
      },
      // {
      //   id: "8_4",
      //   title: "Other",
      //   value: "Other",
      //   buttonType: BUTTON_TYPE.RADIO,
      //   freeType: true,
      // },
    ],
    divider: true,
  },
  8: {
    nr: 3,
    divider: true,
    visualNode: {
      label: "3",
      top: "-13px",
      left: "calc(50% - 20px)",
    },
    highlightNode: {
      top: "0px",
      left: "",
      width: "100%",
      height: "10px",
    },
    title: "What is the type of waterproofing?",
    nodes: [
      {
        id: "8_1_1",
        title: "EPDM",
        value: "EPDM",
      },
      {
        id: "8_1_2",
        title: "PVC / EVA",
        value: "PVC / EVA",
      },
      {
        id: "8_1_3",
        title: "Bitumen",
        value: "Bitumen",
      },
    ],
  },
  9: {
    // nr: 3,
    divider: true,
    visualNode: {
      label: "3",
      top: "-13px",
      left: "calc(50% - 20px)",
    },
    highlightNode: {
      top: "0px",
      left: "",
      width: "100%",
      height: "10px",
    },
    title: "Colour of the waterproofing membrane:",
    nodes: [
      {
        id: "'10_1",
        title: "Dark (Black, anthracite)",
        value: "Dark (Black, anthracite)",
      },
      {
        id: "'10_2",
        title: "Middle (Grey)",
        value: "Middle (Grey)",
      },
      {
        id: "'10_3",
        title: "Light (White, beige)",
        value: "Light (White, beige)",
      },
      {
        id: "'10_4",
        title: "Green, red & brown",
        value: "Green, red & brown",
      },
    ],
  },
  // 10: {
  //   visualNode: {
  //     label: "3",
  //     top: "-13px",
  //     left: "calc(50% - 20px)",
  //   },
  //   highlightNode: {
  //     top: "0px",
  //     left: "",
  //     width: "100%",
  //     height: "10px",
  //   },
  //   title: "Shade",
  //   nodes: [
  //     {
  //       id: "11_1",
  //       title: "Yes",
  //       value: "Yes",
  //       icon: shadow,
  //     },
  //     {
  //       id: "11_2",
  //       title: "No",
  //       value: "No",
  //       icon: noShadow,
  //       skipSteps: [11],
  //     },
  //   ],
  // },
  10: {
    visualNode: {
      label: "3",
      top: "-13px",
      left: "calc(50% - 20px)",
    },
    highlightNode: {
      top: "0px",
      left: "",
      width: "100%",
      height: "10px",
    },
    divider: false,
    title: "Shade is caused by",
    nodes: [
      {
        id: "12_1",
        title: "Solar panel",
        value: "Solar panel",
      },
      {
        id: "12_2",
        title: "Terrace (wood or stone)",
        value: "Terrace (wood or stone)",
      },
      {
        id: "12_3",
        title: "Vegetation (0 - 100 mm)",
        value: "Vegetation (0 - 100 mm)",
      },
      {
        id: "12_4",
        title: "Pebbles (max. 120 mm)",
        value: "Pebbles (max. 120 mm)",
      },
      {
        id: "12_5",
        title: "Building",
        value: "Building",
      },
      {
        id: "12_6",
        title: "No shade",
        value: "No shade",
      },
    ],
  },
  11: {
    nr: 4,
    divider: true,
    visualNode: {
      label: "4",
      top: "155px",
      left: "-45px",
    },
    highlightNode: {
      top: "165px",
      left: "0px",
      width: "100%",
      height: "11px",
    },
    title: "Which vapour control layer (VCL) do you want to use?",
    coverImage: vcl,
    nodes: [
      {
        id: "1_2",
        title: "Majrex",
        value: "Majrex",
      },
      {
        id: "1_1",
        title: "Majpell 5 / Majpell 5 R",
        value: "Majpell 5 / Majpell 5 R",
      },
    ],
    warning: [
      {
        message:
          "In addition to the tried-and-tested SIGA adhesive products, the use of the Majrex 200 vapor control layer offers you even greater security.<br></br> With its hygrobrid technology and even more security, it fulfills the desire of many tradespeople to get everything from a single source. A large number of different applications can be covered with the universally applicable and easy-to-use vapor control layer.",
        rule: "1_1",
      },
    ],
  },
  12: {
    nr: 5,
    visualNode: {
      label: "5",
      top: "0px",
      left: "-40px",
    },
    highlightNode: {
      top: "6px",
      left: "",
      width: "100%",
      height: "20px",
    },
    divider: true,
    multipleChoice: true,
    mustSelectAll: true,
    title: "External over-insulation (bio-based insulation is excluded):",
    nodes: [
      {
        id: "9_1",
        title: "Minimum insulation thickness in the layer (mm):",
        buttonType: BUTTON_TYPE.CHECKBOX,
        value: "",
        childNodes: [
          { id: "9_1_1", value: "40mm", title: "40mm", parent: "9_1" },
          { id: "9_1_2", value: "60 mm", title: "60 mm", parent: "9_1" },
          {
            id: "9_1_3",
            value: "80 mm",
            title: "80 mm",
            parent: "9_1",
          },
          { id: "9_1_4", value: "100mm", title: "100mm", parent: "9_1" },
          { id: "9_1_5", value: "120", title: "120", parent: "9_1" },
          { id: "9_1_6", value: ">120", title: ">120", parent: "9_1" },
        ],
      },
      {
        id: "9_2",
        title: "Lambda value λ(w/mK):",
        disabled: true,
        buttonType: BUTTON_TYPE.CHECKBOX,
        value: "",
        childNodes: [
          {
            id: "9_2_1",
            title: "0.036 - 0.040",
            value: "0.036 - 0.040",
            parent: "9_2",
          },
          {
            id: "9_2_2",
            title: "0.031 - 0.035",
            value: "0.031 - 0.035",
            parent: "9_2",
          },
          {
            id: "9_2_3",
            title: "0.026 - 0.030",
            value: "0.026 - 0.030",
            parent: "9_2",
          },
          {
            id: "9_2_4",
            title: "0.025 or less",
            value: "0.025 or less",
            parent: "9_2",
          },
        ],
      },
    ],
  },
};

const formFields: FormField[] = [
  {
    label: "Company",
    placeholder: "Company",
    id: "form_company",
    value: "",
    required: true,
    type: "text",
  },
  {
    label: "Name / First name",
    placeholder: "Name / First name",
    id: "form_name",
    value: "",
    required: true,
    type: "text",
  },
  {
    label: "Street and house number",
    placeholder: "Street and house number",
    id: "form_street",
    value: "",
    required: true,
    type: "text",
  },
  {
    label: "Postal code",
    placeholder: "Postal code",
    id: "form_postal",
    value: "",
    required: true,
    type: "text",
  },
  {
    label: "City",
    placeholder: "City",
    id: "form_city",
    value: "",
    required: true,
    type: "text",
  },
  {
    label: "Country",
    placeholder: "Country",
    id: "form_country",
    value: "DE",
    required: true,
    type: "text",
  },
  {
    label: "Email",
    placeholder: "Email",
    id: "form_email",
    value: "",
    required: true,
    type: "email",
  },
  {
    label: "Phone number",
    placeholder: "Phone number",
    id: "form_phone",
    value: "",
    required: true,
    type: "text",
  },
  {
    label: "SIGA Contact Person's Email",
    placeholder: "SIGA Contact Person's Email",
    id: "form_siga_contact_person",
    value: "",
    required: true,
    type: "email",
  },
  // ------ contstruction site data
  {
    label: "Construction site name",
    placeholder: "Construction site name",
    id: "form_construction_site_data_name",
    value: "",
    required: true,
    type: "text",
  },
  {
    label: "Builder",
    placeholder: "Builder",
    id: "form_construction_site_data_builder",
    value: "",
    required: true,
    type: "text",
  },
  {
    label: "Street and house number",
    placeholder: "Street and house number",
    id: "form_construction_site_data_street",
    value: "",
    required: true,
    type: "text",
  },
  {
    label: "Postal code",
    placeholder: "Postal code",
    id: "form_construction_site_data_postal",
    value: "",
    required: true,
    type: "text",
  },
  {
    label: "City",
    placeholder: "City",
    id: "form_construction_site_data_city",
    value: "",
    required: true,
    type: "text",
  },
  {
    label: "Country",
    placeholder: "Country",
    id: "form_construction_site_data_country",
    value: "DE",
    required: true,
    disabled: true,
    type: "text",
  },
];

export const formData: FormData = {
  sections: [
    { from: 0, to: 9 },
    {
      sectionTitle: "Construction site address",
      from: 9,
    },
  ],
  fields: formFields,
  submitButton: {
    title: "Continue",
  },
};

export default dummyData;
