import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import countries_en from "./countries_en.json";
import countries_de from "./countries_de.json";
import countries_fr from "./countries_fr.json";
import styled from "styled-components";
import { ControllerRenderProps } from "react-hook-form";
import { useParams } from "react-router-dom";
interface CountrySelectorProps {
  label: string;
  placeholder: string;
  id: string;
  value: string;
  required: boolean;
  disabled?: boolean;
  error: boolean;
  helperText: string;
  field: ControllerRenderProps<
    {
      [key: string]: string;
    },
    `form-field-${string}`
  >;
}

const AutocompleteWrapper = styled.div`
  flex: 0 0 90%;
  .MuiAutocomplete-inputRoot {
    height: 40px;
    width: 100%;
    padding-top: 2px !important;
    padding-bottom: 0 !important;
  }
`;

const CountrySelector: React.FC<CountrySelectorProps> = ({
  error,
  helperText,
  placeholder,
  id,
  value,
  required,
  field,
  disabled = false,
}) => {
  const { lang } = useParams();
  const countries = (
    lang === "de" ? countries_de : lang === "fr" ? countries_fr : countries_en
  ).filter((country) => ["DE", "CH"].includes(country.code));
  return (
    <AutocompleteWrapper>
      <Autocomplete
        sx={{ height: 40, width: "100%" }}
        options={countries}
        autoHighlight
        value={countries.find((c) => c.code === value) || null}
        getOptionLabel={(option) => option.label}
        renderOption={(props, option) => (
          <Box
            component="li"
            sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
            {...props}
          >
            <img
              loading="lazy"
              width="20"
              src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`}
              srcSet={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x`}
              alt=""
            />
            {option.label}
          </Box>
        )}
        renderInput={(params) => (
          <TextField
            {...params}
            {...field}
            name={`form-field-${id}`}
            sx={{ height: 40 }}
            hiddenLabel
            placeholder={placeholder}
            error={error}
            helperText={helperText}
            required={required}
            value={countries.find((c) => c.code === value) || undefined}
            inputProps={{
              ...params.inputProps,
              autoComplete: "new-password", // disable autocomplete and autofill
            }}
          />
        )}
        onChange={(_event, data) => field.onChange(data?.code ?? "")}
      />
    </AutocompleteWrapper>
  );
};

export default CountrySelector;

export interface CountryType {
  code: string;
  label: string;
  phone: string;
  suggested?: boolean;
}
