import { KeyboardArrowLeft } from "@mui/icons-material";
import { Button, styled as MuiStyled } from "@mui/material";
import styled from "styled-components";
import { Title } from "../Title";
import { TitleRow } from "../TitleRow";
import printer from "../../assets/printer.svg";
import { useAppSelector } from "../../hooks";
import { DataNode } from "../../data/dummyData";
import { Fragment, useEffect, useRef } from "react";
import { useNavigate, useParams } from "react-router-dom";
import sigaRoof from "../../assets/sigaRoof.svg";
import sigaPitchedRoof from "../../assets/sigaPitchedRoof.svg";
import SVG from "react-inlinesvg";
import logo from "../../assets/logo.svg";
import usePassword from "../../hooks/usePassword";
import { FormattedMessage, IntlShape, useIntl } from "react-intl";
import { isNumeric, sanitizeWarningMessage } from "../../utils";
import { HowToUseButton, WarningContainer } from "../../styledComponents";
import { SupportedLanguage } from "../../App";
import { CheckedRadio } from "../../reducers/appData";
import dompurify from "dompurify";
import BreadcrumbsContainer from "../Breadcrumbs/BreadcrumbsContainer";
import { ForwardArrow } from "../ForwardArrow";
import { useBreadcrumbs } from "../../data/breadcrumbs";
import countries_de from "../CountrySelector/countries_de.json";
import countries_fr from "../CountrySelector/countries_fr.json";
import countries_en from "../CountrySelector/countries_en.json";
import FormattedNode from "../WarrantyLetter/FormattedNode";
import dayjsExtended from "../../utils/dayjsExtended";
import { MEDIA_SMALL } from "../../constants";
import scrollFunction from "../../utils/scrollFunction";
import { API, letterOrder } from "../../data/appData";

const sanitizer = dompurify.sanitize;

const ButtonArea = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 12px;
  margin-bottom: 24px;
  @media print {
    display: none;
  }
`;

const BackButton = MuiStyled(Button)`
    color: rgba(0,0,0,0.4);
    text-transform: initial;
`;

const PrintButton = MuiStyled(Button)`
    background-image: url(${printer});
    background-repeat: no-repeat;
    background-position: 39px center;
    padding: 0 55px 0 85px;
    height: 46px;
    border: 1px solid #000;
    border-radius:40px;
    border-color: #000 !important;
    color: #000;
    font-weight:bold;
    text-transform: initial;
    &:hover {
        background-color: rgba(0,0,0,0.03);
    }
`;

const PrintLogoArea = styled.div`
  display: none;
  @media print {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 18px 0;
  }
`;
const LogoImage = styled.img`
  height: 34px;
`;

const InfoBox = styled.div.attrs((props: { wrap: string }) => props)`
  border: 1px solid #e0e0e0;
  box-shadow: 1px 7px 24px -13px rgba(0, 0, 0, 0.12);
  border-radius: 3px;
  padding: 0 45px 45px 45px;
  margin-bottom: 32px;
  @media print {
    margin-bottom: 16px;
  }
  display: flex;
  flex-wrap: ${(props) => (props.wrap === "true" ? "wrap" : "no-wrap")};
  ${MEDIA_SMALL} {
    padding: 0 24px 24px 24px;
    margin: 32px 16px;
    &.answers {
      flex-direction: column-reverse;
    }
  }
  h3 {
    font-size: 20px;
    @media print {
      font-size: 14px;
      margin: 6px 0;
    }
  }
  .contact-box {
    padding-top: 27px;
    @media print {
      padding-top: 16px;
      padding-bottom: 16px;
    }
    display: flex;
    flex: 0 0 100%;
  }
  .info {
    display: flex;
    flex-wrap: wrap;
    max-width: 100%;
    @media print {
      max-width: 100%;
    }
    font-size: 16px;
    &-field {
      flex: 0 0 50%;
      ${MEDIA_SMALL} {
        flex: 0 0 100%;
      }
      padding-bottom: 10px;
      padding-top: 20px;
      @media print {
        font-size: 12px;
        padding-bottom: 4px;
        padding-top: 8px;
      }
      display: flex;
    }
    &-key {
      font-weight: bold;
      flex: 0 0 50%;
    }
    &-value {
      padding-left: 16px;
      color: rgba(0, 0, 0, 0.6);
      word-break: break-word;
      padding-right: 16px;
    }
  }
  .warning-message {
    margin: 24px 0;
  }
  .answer-area {
    padding-top: 27px;
    flex: 1 0 60%;
    ${MEDIA_SMALL} {
      flex: 0 0 100%;
      flex-direction: column;
    }
  }
  .image-area {
    flex: 0 1 50%;
    padding-top: 45px;
    margin-left: 20px;
    ${MEDIA_SMALL} {
      flex: 0 0 100%;
      max-width: 300px;
      width: 100%;
      margin: 0 auto;
      flex-direction: column;
    }
  }
`;

const RoofSVG = styled(SVG)`
  width: 100%;
  background-color: white;
`;

const StatementArea = styled.div`
  margin: 60px 0;
  text-align: center;
  line-height: 20px;
  @media print {
    margin: 30px auto;
    font-size: 10px;
    line-height: 12px;
    width: 70%;
  }
`;

const AddressArea = styled.div`
  margin-bottom: 60px;
  font-size: 15px;
  @media print {
    margin: 0 auto;
    margin-bottom: 30px;
    font-size: 10px;
    width: 70%;
  }
  text-align: center;
  line-height: 15px;
  p {
    margin: 5px 0;
    @media print {
      line-height: 12px;
      margin: 0;
    }
  }
`;

const AcceptButtonContainer = styled.div`
  width: 100%;
  margin-top: 32px;
  margin-bottom: 64px;
  display: flex;
  justify-content: space-between;
  .warning-message {
    margin-top: 16px;
    max-width: 520px;
  }
  ${MEDIA_SMALL} {
    width: calc(100% - 32px);
    padding: 0 16px;
  }
`;

const AcceptButton = styled(Button)`
  width: 100%;
  max-width: 312px;
  border-radius: 40px !important;
  text-transform: initial !important;
  font-weight: 300 !important;
  height: 45px;
  ${MEDIA_SMALL} {
    max-width: 170px;
  }
`;

const nodeValue = (dn: DataNode) => ({
  value: dn.selectValue ?? dn.value,
  title: dn.title,
});

const printMargin = "14px";
const getPageMargins = () => {
  return `@media print { @page { margin: ${printMargin} !important; } }`;
};

const statementMsg =
  'Based on our calculation with the hygrothermal simulation program (EN 15026) "Delphin - The IBK institute in Dresden / Germany" we can adopt the following statement:\nIn any case, it must be ensured that the minimum thermal insulation requirements are met. This letter refers exclusively to the moisture-related functionality of the described construction. If the general terms and conditions are met,\n for this construction, the use of the vapor barrier is appropriate Majrex SIGA SIGA Cover AG gives you full warranty on all properties guaranteed in the instruction manual for SIGA Majrex.\nApply the SIGA products always according to the SIGA instruction manual. Professional planning, execution and control on-site is required. To ensure a proper hygrothermic function, an accurately assembled airtight-layer is essential.';

const showCheckedRadio = (
  checkedRadios: CheckedRadio[],
  id: string,
  level: number
) => {
  const val = checkedRadios.find(
    (e) => e.id === id && e.level === level
  )?.value;
  if (val?.length) {
    return ` - ${val}`;
  } else {
    return "";
  }
};

const NodeValue: React.FC<{
  intl: IntlShape;
  node: DataNode;
  level: number;
  checkedRadios: CheckedRadio[];
}> = ({ intl, node, level, checkedRadios }) => (
  <>
    {isNumeric(nodeValue(node).value)
      ? nodeValue(node).value
      : `${intl.formatMessage({
          id: nodeValue(node).value,
          defaultMessage: nodeValue(node).value,
        })} ${showCheckedRadio(checkedRadios, node.id, level)}`}
  </>
);
const CalculationConfirmation = () => {
  const navigate = useNavigate();
  // const [sumbissionTimestamp, setSubmissionTimestamp] = useState(0);
  const intl = useIntl();
  const hasPassword = usePassword();
  let componentRef = useRef(null);
  const formData = useAppSelector((state) => state.appData.formData);
  const formTimestamp = useAppSelector((state) => state.appData.formTimestamp);
  const data = useAppSelector((state) => state.appData.data);
  const checkedRadios = useAppSelector((state) => state.appData.checkedRadios);
  const { lang } = useParams();
  const breadcrumbs = useBreadcrumbs();
  const dayjsObject = dayjsExtended(lang as string);
  dayjsObject.locale(lang);
  const countries =
    lang === "de" ? countries_de : lang === "fr" ? countries_fr : countries_en;

  useEffect(() => {
    if (componentRef?.current) {
      scrollFunction();
    }
  }, [componentRef]);

  const surveyCompleted = useAppSelector(
    (state) => state.appData.lastLevelReached
  );

  useEffect(() => {
    if (!surveyCompleted) {
      navigate(`/${lang}`, { replace: true });
    }
  }, [surveyCompleted, hasPassword, navigate, lang]);

  return (
    <div ref={componentRef}>
      <style>{getPageMargins()}</style>
      <PrintLogoArea>
        <LogoImage src={logo} alt={"SIGA"} />
      </PrintLogoArea>
      <TitleRow
        leftSide={<></>}
        center={
          <Title
            title={
              <FormattedMessage
                id={`Calculation Confirmation`}
                defaultMessage={"Calculation Confirmation"}
              />
            }
            color={"#000"}
          />
        }
        rightSide={
          <HowToUseButton
            onClick={() => navigate(`/${lang}/how-to-use`)}
            color="primary"
            label={intl.formatMessage({
              id: `How to use?`,
              defaultMessage: `How to use?`,
            })}
          />
        }
      />
      <BreadcrumbsContainer
        breadcrumbs={[
          breadcrumbs[0],
          { ...breadcrumbs[1], theState: "current" },
          ...breadcrumbs.slice(2),
        ]}
      />
      <InfoBox wrap="true">
        {formData.sections.map((s, i) => (
          <>
            <div className="contact-box">
              <h3>
                {i === 0 ? (
                  <FormattedMessage
                    id={`Contact information`}
                    defaultMessage={"Contact information"}
                  />
                ) : (
                  <FormattedMessage
                    id={s.sectionTitle}
                    defaultMessage={s.sectionTitle}
                  />
                )}
              </h3>
            </div>
            <div className="info">
              {formData.fields.slice(s.from, s.to).map((f, i) => (
                <div className="info-field" key={i}>
                  <div className="info-key">
                    {intl.formatMessage({
                      id: f.label,
                      defaultMessage: f.label,
                    })}
                    :
                  </div>
                  <div className="info-value">
                    {f.id.indexOf("country") !== -1
                      ? countries.find((c) => c.code === f.value)?.label
                      : f.value}
                  </div>
                </div>
              ))}
            </div>
          </>
        ))}
      </InfoBox>
      <InfoBox className={"answers"}>
        <div className="answer-area">
          <Fragment>
            <div className="info-field">
              <div className="info-key">
                {intl.formatMessage({
                  id: "Date of confirmation:",
                  defaultMessage: "Date of confirmation:",
                })}
              </div>
              <div className="info-value">
                {dayjsObject(formTimestamp).format("DD/MM/YYYY HH:mm")}
              </div>
            </div>
          </Fragment>
          {surveyCompleted &&
            letterOrder[data.find((e) => !!e.api)?.api || API.FLAT_ROOF].map(
              (_key, i) => {
                const level = data.findIndex((d) => d.id === _key);
                const levelData = data[level];
                // const selectedNode = levelData.nodes.find((n) => n.selected);
                return (
                  <FormattedNode
                    data={data}
                    i={i}
                    intl={intl}
                    key={i}
                    theKey={level.toString()}
                    lang={lang as SupportedLanguage}
                  />
                );
                // return (
                //   <Fragment key={i}>
                //     {selectedNode &&
                //       levelData.title &&
                //       !levelData.mustSelectAll &&
                //       nodeValue(selectedNode).value && (
                //         <div className="info-field">
                //           <div className="info-key">
                //             {addCol(
                //               intl.formatMessage({
                //                 id: levelData.title,
                //                 defaultMessage: levelData.title,
                //               })
                //             )}
                //           </div>
                //           <div className="info-value">
                //             <NodeValue
                //               intl={intl}
                //               node={selectedNode}
                //               level={level}
                //               checkedRadios={checkedRadios}
                //             />
                //           </div>
                //         </div>
                //       )}
                //     {levelData.mustSelectAll &&
                //       levelData.nodes
                //         .filter((n) => n.selected)
                //         .map(
                //           (nn, i) =>
                //             nodeValue(nn).title &&
                //             nodeValue(nn).value && (
                //               <div key={i} className="info-field">
                //                 <div className="info-key">
                //                   {addCol(
                //                     intl.formatMessage({
                //                       id: nodeValue(nn).title,
                //                       defaultMessage: nodeValue(nn).title,
                //                     })
                //                   )}
                //                 </div>
                //                 <div className="info-value">
                //                   <NodeValue
                //                     intl={intl}
                //                     node={nn}
                //                     level={level}
                //                     checkedRadios={checkedRadios}
                //                   />
                //                 </div>
                //               </div>
                //             )
                //         )}
                //     {levelData.warning &&
                //       levelData.warning.map((wMsg, i) => (
                //         <WarningContainer
                //           key={i}
                //           className={`warning-message ${
                //             levelData.nodes?.find(
                //               (n) =>
                //                 (n.id === wMsg.rule || n.value === wMsg.rule) &&
                //                 n.selected
                //             )
                //               ? "open"
                //               : "hidden"
                //           }`}
                //           dangerouslySetInnerHTML={{
                //             __html: sanitizeWarningMessage(
                //               wMsg.message,
                //               lang as SupportedLanguage,
                //               intl
                //             ),
                //           }}
                //         />
                //       ))}
                //   </Fragment>
                // );
              }
            )}
        </div>
        <div className="image-area">
          <RoofSVG
            src={
              data.find((e) => !!e.api)?.api === API.FLAT_ROOF
                ? sigaRoof
                : sigaPitchedRoof
            }
          />
        </div>
      </InfoBox>
      <AcceptButtonContainer>
        <AcceptButton
          variant="contained"
          disableElevation
          // disabled={submitButtonDisabled}
          // onClick={showResults}
          onClick={() => navigate(`/${lang}/step-3`)}
        >
          {intl.formatMessage({ defaultMessage: "Continue", id: "Continue" })}
          <ForwardArrow disabled={false} />
        </AcceptButton>
        <BackButton
          variant="text"
          color="inherit"
          onClick={() => navigate(`/${lang}`)}
        >
          <KeyboardArrowLeft />
          {<FormattedMessage id={`Back`} defaultMessage={"Back"} />}
        </BackButton>
      </AcceptButtonContainer>
      {/* <StatementArea>
        {
          <div
            dangerouslySetInnerHTML={{
              __html: sanitizer(
                `<p>${intl
                  .formatMessage({
                    id: statementMsg,
                    defaultMessage: statementMsg,
                  })
                  .replaceAll("\n", "</p><p>")}`
              ),
            }}
          ></div>
        }
      </StatementArea>
      <AddressArea>
        <p>{`SIGA Cover AG Rütmattstrasse 7 CH-6017 Ruswil`}</p>
        <p>
          {`${intl.formatMessage({ id: "Email", defaultMessage: "Email" })}: `}
          <a href="mailto:technik@siga.swiss">{`technik@siga.swiss`}</a>
        </p>
        <p>
          {`Internet: `}
          <a href="https://www.siga.swiss">{`www.siga.swiss`}</a>
        </p>
      </AddressArea> */}
    </div>
  );
};

export default CalculationConfirmation;
