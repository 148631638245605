import SVG from "react-inlinesvg";
import styled, { css, keyframes } from "styled-components";
import {
  MAIN_GREY,
  MEDIA_LARGE,
  MEDIA_MEDIUM,
  MEDIA_SMALL,
  MEDIA_SUPER_SMALL,
} from "../../constants";
import classNames from "classnames";
import { useAppSelector } from "../../hooks";
import { NumberCircle } from "../NumberCircle";
import sigaLogo from "../../assets/logo.svg";
import house_placeholder from "../../assets/images/house_placeholder.svg";

const originalHeight = 195.85;
const smallerHeight = 152.33;
const superSmallHeight = 110;

const diff = originalHeight / smallerHeight;
const small_diff = originalHeight / superSmallHeight;

const pixelDiff = (str: string, divider: number) =>
  `${parseInt(str.split("px")[0], 10) / divider}px`;

const MainImageWrapper = styled.div.attrs(
  (props: { dimensions: { width: number; height: number } }) => props
)`
  width: 496px;
  height: 350px;
  ${MEDIA_MEDIUM} {
    width: 380px;
    height: 250px;
  }
  ${MEDIA_LARGE} {
    position: absolute;
    right: 32px;
    left: auto;
  }
  ${MEDIA_SMALL} {
    position: relative;
    padding-right: calc((100% - 380px) / 2);
    padding-left: calc((100% - 380px) / 2);
    right: 0;
    height: 250px;
    :after {
      content: "";
      width: 100%;
      height: 30px;
      bottom: -31px;
      position: absolute;
      left: 0;
      background: -moz-linear-gradient(
        top,
        #dedede 0%,
        rgba(218, 218, 218, 0) 100%
      ); /* FF3.6-15 */
      background: -webkit-linear-gradient(
        top,
        #dedede 0%,
        rgba(218, 218, 218, 0) 100%
      ); /* Chrome10-25,Safari5.1-6 */
      background: linear-gradient(
        to bottom,
        #dedede 0%,
        rgba(218, 218, 218, 0) 100%
      ); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
      filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#000000', endColorstr='#00e5e5e5',GradientType=0 ); /* IE6-9 */
      pointer-events: none;
      opacity: 0;
      transition: opacity 0.3s ease-in-out;
    }
    &.sticky {
      :after {
        opacity: 1;
      }
    }
  }
  ${MEDIA_SUPER_SMALL} {
    width: 280px;
    padding-right: calc((100% - 280px) / 2);
    padding-left: calc((100% - 280px) / 2);
  }
  background-color: ${MAIN_GREY};
`;

function subtractPercentage(percentageStr: string, num: number) {
  // Remove the '%' sign and convert the string to a number
  let percentage = parseFloat(percentageStr);

  // Subtract the given number
  let result = percentage - num;

  // Return the result as a percentage string
  return result + "%";
}

const ImageInnerWrapper = styled.div.attrs(
  (props: { roofChosen: boolean; imgTop?: string }) => props
)`
  width: 90%;
  height: ${(props) => (props.roofChosen ? "auto" : "calc(350px - 50%)")};
  top: ${(props) => (props.imgTop ? props.imgTop : "25%")};
  ${MEDIA_MEDIUM} {
    height: ${(props) => (props.roofChosen ? "auto" : "calc(250px - 50%)")};
    top: ${(props) =>
      props.imgTop ? subtractPercentage(props.imgTop, 3) : "20%"};
  }
  ${MEDIA_SUPER_SMALL} {
    top: 20%;
  }
  background: ${(props) => (props.roofChosen ? "white" : "transparent")};
  position: relative;
  left: 5%;
`;

const SquarePulse = keyframes`
  0% {background-color: rgba(230, 2, 25, 0.2); }
  50% { background-color: rgba(230, 2, 25, 0.6);}
  100% {background-color: rgba(230, 2, 25, 0.2); }
`;

const SquareAnimation = css`
  animation: ${SquarePulse} 2s infinite;
`;

const RedSquare = styled.div.attrs(
  (props: { top: string; left: string; width: string; height: string }) => props
)`
  background-color: rgba(230, 2, 25, 0.4);
  ${(props) => (props.width.length ? `width: ${props.width};` : "")};
  ${(props) => (props.height.length ? `height: ${props.height};` : "")};
  ${(props) => (props.top.length ? `top: ${props.top};` : "")};

  ${(props) => (props.left.length ? `left: ${props.left};` : "")};
  ${MEDIA_MEDIUM} {
    ${(props) =>
      props.height.length ? `height: ${pixelDiff(props.height, diff)};` : ""};
    ${(props) =>
      props.top.length ? `top: ${pixelDiff(props.top, diff)};` : ""};
  }
  ${MEDIA_SUPER_SMALL} {
    ${(props) =>
      props.height.length
        ? `height: ${pixelDiff(props.height, small_diff)};`
        : ""};
    ${(props) =>
      props.top.length ? `top: ${pixelDiff(props.top, small_diff)};` : ""};
  }
  ${SquareAnimation};
  position: absolute;
  z-index: 10;
`;

const RoofSVG = styled(SVG)`
  width: 100%;
  background-color: white;
  position: absolute;
`;

const NumberCircleContainer = styled.div.attrs(
  (props: { top?: string; left?: string; isActive?: boolean }) => props
)`
  position: absolute;
  z-index: 20;
  top: ${(props) => props.top ?? "0"};
  left: ${(props) => props.left ?? "0"};
  ${MEDIA_MEDIUM} {
    top: ${(props) => pixelDiff(props.top ?? "0", diff)};
    left: ${(props) => pixelDiff(props.left ?? "0", diff)};
  }
  ${MEDIA_SUPER_SMALL} {
    top: ${(props) => pixelDiff(props.top ?? "0", small_diff)};
    left: ${(props) => pixelDiff(props.left ?? "0", small_diff)};
  }
  ${(props) => (props.isActive ? `transform: scale(1.3);` : "")}
`;

const SigaSVG = styled(SVG)`
  text-align: center;
  position: relative;
  background: transparent;
  // -webkit-filter: grayscale(100%);
  // filter: grayscale(100%);
  // opacity: 0.15;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
`;

const ImageArea: React.FC<{
  dimensions: { width: number; height: number };
  sticky: boolean;
  stickyRef: React.MutableRefObject<any>;
}> = ({ dimensions, sticky, stickyRef }) => {
  const currentLevel = useAppSelector((state) => state.appData.currentLevel);
  const lastLevelReached = useAppSelector(
    (state) => state.appData.lastLevelReached
  );
  const roofChosen = useAppSelector((state) => state.appData.roofChosen);
  const data = useAppSelector((state) => state.appData.data);
  const visualNodes = data
    .filter((key) => key.visualNode)
    .map((nn) => ({
      node: nn.visualNode,
      level: data.findIndex((key) => key.id === nn.id),
      imgTop: nn.visualNode?.imgTop,
    }));
  const highlightNodes = data
    .filter((key) => key.highlightNode)
    .map((nn, i) => ({
      node: nn.highlightNode,
      level: data.findIndex((key) => key.id === nn.id),
    }));
  const currentImage = visualNodes.find((n) => n.level === currentLevel)?.node
    ?.img;
  const imgTop = visualNodes.find((n) => n.level === currentLevel)?.imgTop;
  return (
    <MainImageWrapper
      dimensions={dimensions}
      ref={stickyRef}
      className={classNames({ sticky })}
    >
      <ImageInnerWrapper imgTop={imgTop} roofChosen={!!currentImage}>
        {visualNodes.map(
          (n, i) =>
            n.node?.label &&
            n.level <= currentLevel && (
              <NumberCircleContainer
                key={i}
                top={n.node.top}
                left={n.node.left}
                isActive={n.level === currentLevel && !lastLevelReached}
              >
                <NumberCircle
                  nr={parseInt(n.node?.label, 10)}
                  isPulsing={n.level === currentLevel}
                  size={"30px"}
                  spanHeight={"16px"}
                  fontSize={"14px"}
                  red={n.level === currentLevel && !lastLevelReached}
                />
              </NumberCircleContainer>
            )
        )}
        {highlightNodes.map(
          (n, i) =>
            n.level === currentLevel &&
            !lastLevelReached && (
              <RedSquare
                key={i}
                top={n.node?.top}
                left={n.node?.left}
                width={n.node?.width}
                height={n.node?.height}
              />
            )
        )}
        {!!currentImage ? (
          <RoofSVG src={currentImage} />
        ) : (
          <SigaSVG src={house_placeholder} />
        )}
      </ImageInnerWrapper>
    </MainImageWrapper>
  );
};

export default ImageArea;
