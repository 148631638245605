import styled from "styled-components";
import {
  SLIGHT_GREY,
  SIGA_MAIN_BLUE,
  MEDIA_SUPER_SMALL,
} from "../../constants";

export type BreadcrumbState = "filled" | "disabled" | "current";

interface BreadcrumbProps {
  title: string;
  nr: number;
  theState: BreadcrumbState;
}

const BreadcrumbComponent = styled.div`
  display: flex;
  flex-direction: column;
  width: 80px;
  ${MEDIA_SUPER_SMALL} {
    width: 80px;
  }
  align-items: center;
`;

const BreadcrumbCircle = styled.span.attrs(
  (props: { theState: BreadcrumbState }) => props
)`
  width: 34px;
  height: 34px;
  line-height: 34px;
  text-align: center;
  border-radius: 100%;
  border: 1px solid
    ${(props) => (props.theState === "disabled" ? SLIGHT_GREY : SIGA_MAIN_BLUE)};
  margin-bottom: 18px;
  font-size: 13px;
  color: ${(props) => (props.theState === "disabled" ? SLIGHT_GREY : "#FFF")};
  background-color: ${(props) =>
    props.theState === "current" ? SIGA_MAIN_BLUE : "#FFF"};
`;

const BreadcrumbTitle = styled.span.attrs(
  (props: { theState: BreadcrumbState }) => props
)`
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  color: ${(props) => (props.theState === "disabled" ? SLIGHT_GREY : "#000")};
  line-height: 16px;
`;

const tick = (
  <svg xmlns="http://www.w3.org/2000/svg" width="12" height="10" fill="none">
    <path
      fill="#008BD2"
      d="M9.872.807 4.834 6.308 1.95 3.838.6 5.422l3.64 3.12.764.65.674-.739 5.72-6.24L9.872.807Z"
    />
  </svg>
);

const Breadcrumb: React.FC<BreadcrumbProps> = ({ nr, theState, title }) => {
  return (
    <BreadcrumbComponent className="breadcrumb">
      <BreadcrumbCircle theState={theState}>
        {theState === "filled" ? tick : nr}
      </BreadcrumbCircle>
      <BreadcrumbTitle className="breadcrumb-title" theState={theState}>
        {title}
      </BreadcrumbTitle>
    </BreadcrumbComponent>
  );
};

export default Breadcrumb;
