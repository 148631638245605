const ForwardArrow: React.FC<{ disabled: boolean }> = ({ disabled }) => (
  <svg
    style={{ marginLeft: "8px" }}
    xmlns="http://www.w3.org/2000/svg"
    width="9"
    height="13"
    fill="none"
  >
    <path
      fill={disabled ? "rgba(0, 0, 0, 0.16)" : "#FFF"}
      d="m8.23 6.5-.61-.64L2.1.11.77 1.39 5.679 6.5l-4.91 5.11 1.33 1.28 5.52-5.75.611-.64Z"
    />
  </svg>
);

export default ForwardArrow;
