import axios from "axios";
import { PdfFormData } from "../reducers/appData";

const downloadForm = async (data: PdfFormData) => {
  return await axios.post(
    `${process.env.REACT_APP_BACKEND_HOST}/v2/download-form`,
    data,
    { responseType: "arraybuffer" }
  );
};

export default downloadForm;
