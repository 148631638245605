import React from "react";
import styled from "styled-components";
import { MAX_WIDTH, MEDIA_SMALL, MEDIA_SUPER_SMALL } from "../../constants";

interface TitleRowProps {
  leftSide: React.ReactNode;
  center: React.ReactNode;
  rightSide: React.ReactNode;
}

const TitleRowContainer = styled.div`
  display: flex;
  align-items: center;
  margin: 0 auto;
  max-width: calc(${MAX_WIDTH}px - 32px);
  padding-right: 16px;
  padding-left: 16px;
  .left-side,
  .right-side {
    flex: 0 0 20%;
  }
  .center {
    flex: 0 0 60%;
    text-align: center;
  }
  .right-side {
    display: flex;
    justify-content: flex-end;
  }
  ${MEDIA_SMALL} {
    flex-direction: column;
    margin-top: 86px;
  }
`;

const TitleRow: React.FC<TitleRowProps> = ({ leftSide, center, rightSide }) => (
  <TitleRowContainer>
    <div className="left-side">{leftSide}</div>
    <div className="center">{center}</div>
    <div className="right-side">{rightSide}</div>
  </TitleRowContainer>
);

export default TitleRow;
