import axios from "axios";
import { MainData } from "../data/dummyData";
import { API, DataSection, SECTION_ID } from "../data/appData";

const p = (n: string) => parseInt(n, 10);
const getNr = (d: MainData, t: string) =>
  Object.keys(d).find((k: string) => d[p(k)].title === t);
const getNrDeep = (d: MainData, t: string) =>
  Object.keys(d).find(
    (k: string) =>
      d[p(k)].title === t || !!d[p(k)].nodes.find((n) => n.title === t)
  );

const getParameters = async (allData: DataSection[], api: API) => {
  const d = allData;
  if (api === API.FLAT_ROOF) {
    const shadeTypeNr = d.findIndex((e) => e.id === SECTION_ID.SHADE);
    const shadeType = shadeTypeNr ? allData[shadeTypeNr] : allData[10];

    const productTypeNr = d.findIndex(
      (e) => e.id === SECTION_ID.VAPOR_CONTROL_LAYER
    );
    const productType = productTypeNr ? allData[productTypeNr] : allData[11];

    const colorNr = d.findIndex(
      (e) => e.id === SECTION_ID.COLOUR_WATERPROOFING
    );
    const color = colorNr ? allData[colorNr] : allData[9];

    const insulationTypeNr = d.findIndex(
      (e) => e.id === SECTION_ID.INSULATION_BETWEEN_RAFTERS
    );
    const insulationType = insulationTypeNr
      ? allData[insulationTypeNr]
      : allData[4];

    const insulationValueNr = d.findIndex(
      (e) => e.id === SECTION_ID.INSULATION_LAYER_THICKNESS
    );
    // "Insulation layer thickness (mm):");
    const insulationValue = insulationValueNr
      ? allData[insulationValueNr]
      : allData[5];

    return await axios.post(
      `${process.env.REACT_APP_BACKEND_HOST}/v2/parameters`,
      {
        productType:
          productType.nodes.find((n) => n.selected)?.value === "Majrex"
            ? "MAJREX"
            : "MAJPELL",
        color: color.nodes.find((n) => n.selected)?.value,
        shade:
          shadeType.nodes.find((n) => n.selected)?.value.toLowerCase() ===
          "no shade"
            ? "No"
            : "Yes",
        shadeType: shadeType.nodes.find((n) => n.selected)?.value,
        insulationType: insulationType.nodes.find((n) => n.selected)?.value,
        insulationValue: insulationValue.nodes.find(
          (n) => n.title.indexOf("Insulation layer thickness") !== -1
        )?.selectValue,
      }
    );
  } else if (api === API.PITCHED_ROOF) {
    const side = d
      ?.find((e) => e.id === SECTION_ID.PITCHED_ROOF_MEMBRANE_SIDE)
      ?.nodes.find((n) => n.selected)?.value;
    const diffusion = d
      ?.find((e) => e.id === SECTION_ID.PITCHED_ROOF_BUILD_UP)
      ?.nodes.find((n) => n.selected)?.value;
    const insulation = d
      .find((e) => e.id === SECTION_ID.PITCHED_ROOF_INSULATION_BETWEEN_RAFTERS)
      ?.nodes.find((n) => n.selected)?.value;
    const insulationThickness = d
      .find((e) => e.id === SECTION_ID.PITCHED_ROOF_MATERIAL_PROPERTIES)
      ?.nodes.find(
        (n) => n.title === "Insulation layer thickness (mm):"
      )?.selectValue;
    const insulationType = d
      .find((e) => e.id === SECTION_ID.PITCHED_ROOF_SEALING_TYPE)
      ?.nodes.find((n) => n.selected)?.value;
    const coveringMaterial = d
      .find((e) => e.id === SECTION_ID.PITCHED_ROOF_COVERING_MATERIAL)
      ?.nodes.find((n) => n.selected)?.value;
    const coveringColor = d
      .find((e) => e.id === SECTION_ID.PITCHED_ROOF_COVERING_COLOR)
      ?.nodes.find((n) => n.selected)?.value;
    const productType = d
      .find((e) => e.id === SECTION_ID.PITCHED_ROOF_VAPOR_CONTROL_LAYER)
      ?.nodes.find((n) => n.selected)?.value;

    return await axios.post(
      `${process.env.REACT_APP_BACKEND_HOST}/v2/pitched_roof_parameters`,
      {
        side,
        diffusion,
        insulation,
        insulationThickness,
        insulationType,
        coveringMaterial,
        coveringColor,
        productType: productType === "Majrex" ? "MAJREX" : "MAJPELL",
      }
    );
  } else {
    return { data: { error: "Invalid API" } };
  }
};

export default getParameters;
