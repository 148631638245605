import { useAppSelector } from "../hooks";
import { Buffer } from "buffer";

const encode = (str: string): string =>
  Buffer.from(str, "binary").toString("base64");

const usePassword = () => {
  const password = useAppSelector((state) => state.appData.password);
  return encode(password) === "c2lnYTIwMjJUZXN0IQ==";
};

export default usePassword;
