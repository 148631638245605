import styled from "styled-components";
import { SIGA_MAIN_BLUE } from "../../constants";
import { useEffect } from "react";
import SVG from "react-inlinesvg";
import feedbackIcon from "../../assets/feedback.svg";

const FeedbackButton = styled.a`
  width: 100%;
  display: inline-block;
  border-radius: 40px !important;
  text-transform: initial !important;
  font-weight: 300 !important;
  font-size: 14px;
  height: 55px;
  line-height: 55px;
  position: relative;
  background-color: ${SIGA_MAIN_BLUE};
  transition: background-color 0.2s linear;
  text-align: center;
  color: white;
  cursor: pointer;
  &:hover {
    background-color: rgb(0, 97, 147);
  }
  .feedback {
    position: absolute;
    right: 50px;
    top: 15px;
    height: 24px;
  }
`;

const EmbedSocialWidget = () => {
  useEffect(() => {
    (function (d) {
      var appziScript = d.createElement("script");
      appziScript.src = `https://w.appzi.io/w.js?token=iF4Ga`;
      d.head.appendChild(appziScript);
    })(document);
  }, []);
  return (
    <FeedbackButton data-az-l="45e4d442-07bd-4ec0-85b5-b1024b67029c">
      Leave Feedback <SVG className="feedback" src={feedbackIcon} />
    </FeedbackButton>
  );
};

export default EmbedSocialWidget;
