export const SIGA_MAIN_BLUE = "#008BD2";

export const MAIN_GREY = "#F5F5F5";

export const SLIGHT_GREY = "rgba(0,0,0,0.4)";

export const TEXT_COLOR = "#000000";

export const WARNING_COLOR = "rgba(230, 2, 25, 1)";

export const MAX_WIDTH = 1240;

export const SIGA_DARK_BLUE = "rgba(33, 77, 111, 1)";

export const DATA_LOADING_LEVEL = 12;

export const sigaDarkBlueOpaque = (value: number) =>
  `rgba(33, 77, 111, ${value})`;

export const MEDIA_SMALL_PX = 870;

export const SIGA_IMAGE_TOP_OFFSET = 60;

export const MEDIA_LARGE = `@media (max-width: 1300px)`;
export const MEDIA_MEDIUM = `@media (max-width: 1050px)`;
export const MEDIA_SMALL = `@media (max-width: ${MEDIA_SMALL_PX}px)`;
export const MEDIA_SMALLER = `@media (max-width: 720px)`;
export const MEDIA_VERY_SMALL = `@media (max-width: 520px)`;
export const MEDIA_SUPER_SMALL = `@media (max-width: 449px)`;

export const valuesToTranslate = [
  "40mm",
  "60 mm",
  "80 mm",
  "100mm",
  "120",
  ">120",
  "other",
  "0.025 or less",
  "North",
  "East",
  "South",
  "West",
];
