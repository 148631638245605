import React from "react";
import styled from "styled-components";
import { MEDIA_LARGE, MEDIA_SMALL } from "../../constants";

const BodySideContainer = styled.div.attrs(
  (props: { percentage: string; bodyName: string }) => props
)`
  flex: 0 0 ${(props) => props.percentage};
  ${MEDIA_LARGE} {
    flex: 0 0 50%;
  }
  ${(props) =>
    props.bodyName === "image-area"
      ? `
      ${MEDIA_LARGE} {
    flex: 0 0 calc(50% - 48px);
    align-items: flex-end;
  }`
      : ""}
  ${MEDIA_SMALL} {
    flex: 0 0 100%;
  }
`;

const BodySide: React.FC<{
  children?: React.ReactNode;
  percentage: string;
  bodyName: string;
  bodyRef?: (node: HTMLDivElement | null) => void;
}> = ({ children, percentage, bodyRef, bodyName }) => {
  return (
    <BodySideContainer
      ref={bodyRef}
      percentage={percentage}
      bodyName={bodyName}
    >
      {children}
    </BodySideContainer>
  );
};

export default BodySide;
