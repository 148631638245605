import styled from "styled-components";
import {
  MEDIA_SMALLER,
  MEDIA_SUPER_SMALL,
  MEDIA_VERY_SMALL,
  SIGA_DARK_BLUE,
} from "../../constants";
import { useIntl } from "react-intl";

const FooterContainer = styled.div`
  width: calc(100% - 32px);
  height: 84px;
  ${MEDIA_SMALLER} {
    height: 120px;
  }
  overflow-x: hidden;
  display: flex;
  margin-top: 48px;
  padding-left: 16px;
  padding-right: 16px;
  background-color: ${SIGA_DARK_BLUE};
  color: #fff;
  font-weight: 500;
  font-size: 18px;
  ${MEDIA_SUPER_SMALL} {
    font-size: 16px;
  }
  position: absolute;
  bottom: 0;
  a {
    color: #fff;
    text-decoration: none;
    &:hover {
      text-decoration: underline;
    }
  }
  ${MEDIA_SMALLER} {
    .stick-with-us {
      display: none;
    }
    height: 90px;
  }
`;

const FooterInnerContainer = styled.div.attrs(
  (props: { maxWidth: string }) => props
)`
  max-width: ${(props) => props.maxWidth};
  margin: 0 auto;
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: row;
  padding: 6px 0;
  ${MEDIA_SMALLER} {
    flex-direction: column;
    justify-content: center;
    span,
    a {
      margin: 0px 8px 0;
    }
  }
  justify-content: space-between;
`;

const MiddleText = styled.span`
  font-size: 20px;
  ${MEDIA_VERY_SMALL} {
    font-size: 16px;
    word-wrap: normal;
  }
  vertical-align: middle;
  display: flex;
  align-items: center;
  margin: 0 !important;
  ${MEDIA_SMALLER} {
    margin-bottom: 4px !important;
  }
  ${MEDIA_VERY_SMALL} {
    margin-bottom: 0px !important;
  }
  svg {
    margin-right: 8px;
  }
`;

const Footer: React.FC<{ maxWidth: string }> = ({ maxWidth }) => {
  const intl = useIntl();
  return (
    <FooterContainer>
      <FooterInnerContainer maxWidth={maxWidth}>
        <span className="stick-with-us">Stick with us.</span>
        <MiddleText>
          <svg
            width="27"
            height="27"
            viewBox="0 0 27 27"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M27 13.5C27 9.91926 25.5772 6.4854 23.0459 3.95415C20.5145 1.4229 17.0805 0 13.5 0C9.91953 0 6.4854 1.42277 3.95415 3.95415C1.4229 6.48554 0 9.91953 0 13.5C0 17.0805 1.42277 20.5146 3.95415 23.0459C6.48554 25.5771 9.91953 27 13.5 27C17.0805 27 20.5146 25.5772 23.0459 23.0459C25.5771 20.5145 27 17.0805 27 13.5ZM11.5266 10.4973C11.4865 10.6661 11.458 10.838 11.4401 11.011C11.4401 11.1164 11.3916 11.146 11.2893 11.1428H10.301H9.29062C9.23156 11.1428 9.18304 11.1428 9.18831 11.0563C9.19253 10.3697 9.33597 9.69153 9.60914 9.06084C9.97617 8.27298 10.609 7.64018 11.3968 7.2742C12.672 6.65719 14.1433 6.58337 15.4733 7.0717C16.4257 7.37755 17.1914 8.09262 17.5637 9.02077C17.7852 9.60613 17.8632 10.2347 17.7926 10.857C17.724 11.6206 17.3717 12.3314 16.8043 12.8493C16.3719 13.268 15.9078 13.6593 15.4543 14.0506C15.0999 14.3164 14.8204 14.6697 14.6443 15.0768C14.5357 15.3953 14.4756 15.7286 14.4661 16.065C14.4661 16.1863 14.4471 16.3055 14.4418 16.4236C14.4418 16.4859 14.4207 16.5154 14.3532 16.5154L12.5634 16.5164C12.5065 16.5164 12.4769 16.5006 12.4801 16.4384L12.479 16.4373C12.4685 15.853 12.5286 15.2688 12.6573 14.6992C12.8334 14.1044 13.1741 13.5728 13.6402 13.1657C14.04 12.7955 14.4365 12.4232 14.8173 12.0319V12.0309C15.1379 11.7071 15.3562 11.2957 15.4438 10.8486C15.5925 10.0902 15.3056 9.5281 14.58 9.26336C13.8786 8.99652 13.0992 9.025 12.42 9.34457C11.958 9.56816 11.6268 9.99424 11.5266 10.4973ZM12.2881 18.9969C12.326 18.3609 12.8586 17.8684 13.4946 17.879C14.1317 17.8895 14.6474 18.3979 14.6664 19.0349C14.6759 19.3597 14.5514 19.674 14.3236 19.9061C14.0947 20.1371 13.7826 20.2647 13.4567 20.2604C13.1318 20.2552 12.8238 20.117 12.6024 19.8786C12.3819 19.6403 12.268 19.3218 12.2881 18.9969Z"
              fill="white"
            />
          </svg>

          {intl.formatMessage({
            id: "Customer support",
            defaultMessage: "Customer support",
          })}
          {":"}
          <a
            style={{ textDecoration: "underline", marginLeft: 8 }}
            href="mailto:technik@siga.swiss"
          >
            technik@siga.swiss
          </a>
        </MiddleText>
        <a href="https://siga.swiss">siga.swiss</a>
      </FooterInnerContainer>
    </FooterContainer>
  );
};

export default Footer;
