import styled from "styled-components";
import logo from "../../assets/logo.svg";
import { MAIN_GREY, MEDIA_SMALL, MEDIA_VERY_SMALL } from "../../constants";
import { LanguageSwitcher } from "../LanguageSwitcher";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { resetAllState } from "../../reducers/appData";
import { useAppDispatch } from "../../hooks";

const HeaderContainer = styled.header`
  width: calc(100% - 32px);
  height: 135px;
  padding-left: 16px;
  padding-right: 16px;
  display: flex;
  border-bottom: 1px ${MAIN_GREY} solid;
  margin-bottom: 48px;
  ${MEDIA_SMALL} {
    height: 70px;
    margin-bottom: 0px;
    position: fixed;
    top: 0;
    background: #fff;
    z-index: 100;
  }
`;

const HeaderInnerContainer = styled.div.attrs(
  (props: { maxWidth: string }) => props
)`
  max-width: ${(props) => props.maxWidth};
  margin: 0 auto;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  ${MEDIA_VERY_SMALL} {
    flex-direction: column;
  }
`;

const LogoButton = styled.button`
  background: transparent;
  border: none;
  cursor: pointer;
`;

const LogoImage = styled.img`
  flex: 0 0 177px;
  width: 177px;
  ${MEDIA_SMALL} {
    flex: 0 0 150px;
    width: 150px;
    // margin-bottom: 16px;
  }
  ${MEDIA_VERY_SMALL} {
    margin-top: 16px;
  }
`;

const HeaderArea = styled.div.attrs(
  (props: { justifyContent: string }) => props
)`
  flex: 0 0 33%;
  display: flex;
  justify-content: ${(props) => props.justifyContent};
  ${MEDIA_SMALL} {
    &:last-of-type {
      margin-bottom: 16px;
    }
  }
`;

const Header: React.FC<{ maxWidth: string }> = ({ maxWidth }) => {
  const location = useLocation();
  const { lang } = useParams();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  if (location.pathname.indexOf("warranty-letter") !== -1) {
    return <></>;
  }

  const goBackToStart = () => {
    dispatch(resetAllState());
    navigate(`/${lang}`, { replace: true });
  };
  return (
    <HeaderContainer>
      <HeaderInnerContainer maxWidth={maxWidth}>
        <HeaderArea />
        <HeaderArea justifyContent="center">
          <LogoButton onClick={goBackToStart}>
            <LogoImage src={logo} alt={"SIGA"} />
          </LogoButton>
        </HeaderArea>
        <HeaderArea justifyContent="flex-end">
          {/* <LanguageSwitcher /> */}
        </HeaderArea>
      </HeaderInnerContainer>
    </HeaderContainer>
  );
};

export default Header;
