import {
  FormControlLabel,
  Radio,
  styled as styledComponent,
  TextField,
} from "@mui/material";
import styled from "styled-components";
import { useAppDispatch, useAppSelector } from "../../hooks";
import {
  uncheckAllRadios,
  setCheckedRadios,
  modifyCheckedRadio,
} from "../../reducers/appData";

export interface RadioButtonProps {
  id: string;
  title: string;
  selected: boolean;
  onChange: () => void;
  deselect: () => void;
  disabled?: boolean;
  freeType?: boolean;
  level: number;
}

const SigaFormControlLabel = styledComponent(FormControlLabel)`
    flex: 0 0 auto;
`;

const SigaRadioWrapper = styled.div`
  display: flex;
  flex-wrap: no-wrap;
  align-items: flex-start;
  width: 100%;
`;

const RadioTextField = styledComponent(TextField)`
  flex: 1;
`;

const RadioButton: React.FC<RadioButtonProps> = ({
  id,
  title,
  selected,
  disabled,
  freeType,
  level,
  onChange,
  // deselect,
}) => {
  const dispatch = useAppDispatch();
  const checkedRadios = useAppSelector((state) => state.appData.checkedRadios);
  // const [freeTypeText, setText] = useState("");
  const masterOnChange = (
    _event: React.ChangeEvent<HTMLInputElement>,
    _checked: boolean
  ) => {
    if (!freeType) {
      dispatch(uncheckAllRadios());
      onChange();
    } else {
      dispatch(uncheckAllRadios());
      onChange();
      dispatch(setCheckedRadios({ id, level }));
    }
  };
  const modifyRadio = (event: any) => {
    dispatch(modifyCheckedRadio({ id, level, value: event.target.value }));
  };
  const checkedRadioResult = checkedRadios.find(
    (e) => e.id === id && level === e.level
  );
  const isChecked = !!checkedRadioResult;
  return (
    <SigaRadioWrapper>
      <SigaFormControlLabel
        value={title}
        control={
          <Radio
            disabled={disabled}
            checked={freeType ? isChecked : selected}
            onChange={masterOnChange}
            value={title}
            name={id}
            inputProps={{ "aria-label": title }}
          />
        }
        label={title}
      />
      {freeType && isChecked && (
        <RadioTextField
          size="small"
          onChange={modifyRadio}
          value={checkedRadioResult.value ?? ""}
          id={`freeType_${id}`}
          variant="outlined"
          placeholder="Please specify"
        />
      )}
    </SigaRadioWrapper>
  );
};
export default RadioButton;
