import {
  Chip,
  IconButton,
  styled as styledComponent,
  Tooltip,
} from "@mui/material";
import styled from "styled-components";
import { MAIN_GREY, TEXT_COLOR } from "../../constants";
import { ReactComponent as TooltipIcon } from "../../assets/tooltip.svg";
import SVG from "react-inlinesvg";
import { useIntl } from "react-intl";

export interface ChoiceButtonProps {
  title: string;
  selected: boolean;
  icon?: string;
  square: 0 | 1;
  tooltip?: string;
  onClick: () => void;
  disabled?: boolean;
  isLoading: boolean;
}

const ChipChoiceButton = styledComponent(Chip)<{ square: 0 | 1 }>`
  padding: 0 50px;
  height: 45px;
  font-size: 15px;
  color: ${TEXT_COLOR};
  border-color: ${MAIN_GREY};
  border-radius: 40px;
  ${(props) => props.square === 1 && `border-radius: 5px;`}
  margin-right: 25px;
  margin-bottom: 25px;
  .MuiChip-icon {
    margin-right: 8px;
  }
`;

const ButtonContainer = styled.div`
  display: inline-block;
`;

const TooltipContainer = styled.div`
  display: block;
`;

const ChoiceButton: React.FC<ChoiceButtonProps> = ({
  title,
  selected,
  icon,
  square,
  tooltip,
  onClick,
  disabled,
  isLoading,
}) => {
  const intl = useIntl();
  const inputProps: { icon?: any } = {};
  if (icon) {
    inputProps.icon = <SVG src={icon} />;
  }
  return (
    <ButtonContainer>
      {tooltip && (
        <TooltipContainer>
          <Tooltip title={tooltip} placement="top">
            <IconButton>
              <TooltipIcon />
            </IconButton>
          </Tooltip>
        </TooltipContainer>
      )}
      <ChipChoiceButton
        color="primary"
        onClick={onClick}
        label={intl.formatMessage({ id: title, defaultMessage: title })}
        disabled={disabled || isLoading}
        square={square}
        {...inputProps}
        variant={selected ? "filled" : "outlined"}
      />
    </ButtonContainer>
  );
};

export default ChoiceButton;
