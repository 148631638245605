import { CheckedRadio } from "../reducers/appData";

export const showCheckedRadio = (
  checkedRadios: CheckedRadio[],
  id: string,
  level: number
) => {
  const val = checkedRadios.find(
    (e) => e.id === id && e.level === level
  )?.value;
  if (val?.length) {
    return ` - ${val}`;
  } else {
    return "";
  }
};
