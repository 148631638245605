import { setupStore } from "./store";
import { Provider } from "react-redux";

import App from "./App";

const StoreWrapper = () => {
  return (
    <div className="App">
      <Provider store={setupStore()}>
        <App />
      </Provider>
    </div>
  );
};

export default StoreWrapper;
