import { Chip, styled as styledComponent } from "@mui/material";
import styled from "styled-components";
import {
  MAIN_GREY,
  MEDIA_SMALL,
  MEDIA_VERY_SMALL,
  SIGA_MAIN_BLUE,
  TEXT_COLOR,
} from "../../constants";
import SVG from "react-inlinesvg";
import { useRef, useState } from "react";
import { useIntl } from "react-intl";
import { isSafari } from "react-device-detect";
import isMobile from "ismobilejs";

export interface VisualButtonProps {
  title: string;
  selected: boolean;
  icon?: string;
  iconPng?: string;
  onClick: () => void;
  disabled?: boolean;
  hoverElementPadding?: string;
}

const ChipVisualButton = styledComponent(Chip)<{ selected: boolean }>`
  padding: 0;
  font-size: 15px;
  color: ${TEXT_COLOR};
  border-color: ${(props) =>
    props.selected ? `${SIGA_MAIN_BLUE} !important` : `${MAIN_GREY}`};
  border-radius: 5px;
  border-width: ${(props) => (props.selected ? "6px" : "1px")};
  /* margin-right: 25px;
  margin-bottom: 25px; */
  width: 120px;
  height: 120px;
  overflow: initial;
  display: inline-flex;
  flex-direction: column;
  text-overflow: initial;
  span {
    white-space: normal;
    display: inline-block;
    text-align: center;
  }
  .MuiChip-icon {
    margin: 0 auto 0px;
  }
  ${MEDIA_VERY_SMALL} {
    width: 120px;
    height: 120px;
    font-size: 14px;
    /* margin-right: 15px; */
  }
  svg {
    max-width:90px;
    max-height: 90px;
  }
`;

const HoverComponent = styled.div.attrs((props: { visible: boolean }) => props)`
  visibility: ${(props) => (props.visible ? "visible" : "hidden")};
  position: fixed;
  background: white;
  padding: 10px;
  z-index: 20;
  border: 0.1em solid ${MAIN_GREY};
  border-radius: 5px;
  transform-origin: top left;
  transform: scale(3);
  img,
  svg {
    max-width: 120px;
    max-height: 120px;
  }
`;

const SafariIcon = styled.div`
  img {
    width: 90px;
    height: 90px;
  }
  background: white;
  padding: 6px;
  border-radius: 5px;
`;

const ChipContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  flex-direction: column;
  width: 180px;
  text-align: center;
  .chip_title {
    padding-top: 16px;
    padding-left: 4px;
    padding-right: 4px;
  }
  margin-bottom: 24px;
`;

const VisualButton: React.FC<VisualButtonProps> = ({
  title,
  selected,
  icon,
  iconPng,
  onClick,
  disabled,
  hoverElementPadding,
}) => {
  const hoverRef = useRef(null);
  const intl = useIntl();
  const [hoverCoordinates, setCoords] = useState({ x: 0, y: 0 });
  const [hoverVisible, setHoverVisible] = useState(false);
  const inputProps: { icon?: any } = {};
  if (icon && iconPng) {
    inputProps.icon = isSafari ? (
      <SafariIcon>
        <img src={iconPng} />
      </SafariIcon>
    ) : (
      <SVG src={icon} />
    );
  }
  const onMouseOver = (ev: any) => {
    const container = hoverRef?.current;
    if (container) {
      const containerSize = (container as any).getBoundingClientRect();
      let x = ev.clientX - 10 - containerSize.width;
      let y = ev.clientY - 10 - containerSize.height;

      // Check if the hover component goes out of bounds
      if (x < 0) {
        x = ev.clientX + 10; // Move to the right side if it goes out of the left bound
      } else if (x + containerSize.width > window.innerWidth) {
        x = ev.clientX - 10 - containerSize.width; // Move to the left side if it goes out of the right bound
      }

      if (y < 0) {
        y = ev.clientY + 10; // Adjust if it goes out of the top bound
      } else if (y + containerSize.height > window.innerHeight) {
        y = ev.clientY - 10 - containerSize.height; // Adjust if it goes out of the bottom bound
      }

      setCoords({ x, y });
    }
  };
  const onMouseEnter = () => {
    if (isMobile(window.navigator).any) {
      return;
    }
    setHoverVisible(true);
  };
  const onMouseLeave = () => {
    if (isMobile(window.navigator).any) {
      return;
    }
    setHoverVisible(false);
  };
  return (
    <>
      <ChipContainer>
        <ChipVisualButton
          onMouseEnter={onMouseEnter}
          onMouseLeave={onMouseLeave}
          onMouseOver={onMouseOver}
          onMouseMove={onMouseOver}
          color="primary"
          // label={intl.formatMessage({ id: title, defaultMessage: title })}
          disabled={disabled}
          onClick={onClick}
          {...inputProps}
          variant="outlined"
          selected={selected}
        />
        <span className="chip_title">
          {intl.formatMessage({ id: title, defaultMessage: title })}
        </span>
      </ChipContainer>
      {icon && (
        <HoverComponent
          ref={hoverRef}
          style={{
            left: `${hoverCoordinates.x}px`,
            top: `${hoverCoordinates.y}px`,
          }}
          visible={hoverVisible}
        >
          {inputProps.icon}
        </HoverComponent>
      )}
    </>
  );
};

export default VisualButton;
