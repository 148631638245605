import styled from "styled-components";
import { Title } from "../Title";
import { TitleRow } from "../TitleRow";
import question from "../../assets/question-blue.svg";
import accordionArrow from "../../assets/accordionArrow.svg";
import SVG from "react-inlinesvg";
import { FormattedMessage, useIntl } from "react-intl";
import {
  MEDIA_MEDIUM,
  MEDIA_SMALL,
  SIGA_DARK_BLUE,
  SIGA_MAIN_BLUE,
} from "../../constants";
import MuiAccordion, { AccordionProps } from "@mui/material/Accordion";
import {
  AccordionSummary as MuiAccordionSummary,
  AccordionDetails as MuiAccordionDetails,
  Typography,
  styled as MuiStyled,
  type AccordionSummaryProps,
  Button,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { KeyboardArrowLeft } from "@mui/icons-material";
import { getQA } from "../../api";
import { useAppDispatch, useAppSelector } from "../../hooks";
import { QA, setQA } from "../../reducers/appData";
import CircularProgress from "@mui/material/CircularProgress";

const MainContainer = styled.div`
  display: flex;
  margin-top: 110px;
  flex-wrap: no-wrap;
  ${MEDIA_MEDIUM} {
    flex-wrap: wrap;
    margin-top: 48px;
  }
  .side {
    &.left {
      flex: 0 0 40%;
    }
    &.right {
      flex: 0 0 60%;
    }
    ${MEDIA_MEDIUM} {
      &.left {
        flex: 0 0 100%;
      }
      &.right {
        flex: 0 0 100%;
      }
    }
  }
`;

const BottomButtonContainer = styled.div`
  width: 100%;
  margin-top: 32px;
  margin-bottom: 64px;
  display: flex;
  justify-content: flex-end;
  ${MEDIA_SMALL} {
    width: calc(100% - 32px);
    padding: 0 16px;
  }
  margin-bottom: 110px;
`;

const BackButton = MuiStyled(Button)`
    color: rgba(0,0,0,0.4);
    text-transform: initial;
`;

const HowToUseWrapper = styled.div`
  width: calc() (100% - 32px);
  padding-left: 16px;
  padding-right: 16px;
`;

const ProgressContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const FAQTitle = styled.h4`
  font-size: 35px;
  margin-top: 0px;
  font-weight: 500;
  max-width: 350px;
  line-height: 42.73px;
  color: ${SIGA_MAIN_BLUE};
  ${MEDIA_MEDIUM} {
    max-width: 100%;
    text-align: center;
    margin-bottom: 20px;
  }
`;

const CustomerSupport = styled.div`
  background-image: url(${question});
  background-position: left center;
  background-repeat: no-repeat;
  padding-left: 47px;
  line-height: 24px;
  font-size: 19px;
  color: ${SIGA_DARK_BLUE};
  a {
    color: ${SIGA_DARK_BLUE};
  }
  ${MEDIA_MEDIUM} {
    br {
      display: none;
    }
    a {
      margin-left: 8px;
    }
    max-width: 320px;
    width: 100%;
    margin: 0 auto;
    min-height: 32px;
    display: flex;
    align-items: center;
    margin-bottom: 48px;
  }
`;

const Accordion = styled((props: AccordionProps) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(() => ({
  "&:not(:last-child)": {
    borderBottom: 0,
  },
  "&:before": {
    display: "none",
  },
}));

const AccordionDetails = MuiStyled(MuiAccordionDetails)(() => ({
  padding: "45px 0px",
  color: `rgba(0,0,0,0.5)`,
}));

const AccordionSummary = styled((props: AccordionSummaryProps) => (
  <MuiAccordionSummary expandIcon={<SVG src={accordionArrow} />} {...props} />
))(({ theme }) => ({
  backgroundColor: "rgba(0, 0, 0, .03)",
  borderBottom: "1px solid rgba(224, 224, 224, 1) !important",
  flexDirection: "row",
  padding: "0 !important",
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(-90deg)",
  },
  "& .MuiAccordionSummary-content": {
    margin: "0 !important",
    padding: "20px 0px",
    "& .MuiTypography-root": {
      fontWeight: 700,
      fontSize: "17px",
      color: "#000",
    },
  },
}));

const HowToUse = () => {
  const [expanded, setExpanded] = useState<string | false>("panel0");
  const navigate = useNavigate();
  const { lang } = useParams();
  const dispatch = useAppDispatch();
  const qaContent = useAppSelector((state) => state.appData.qa);

  useEffect(() => {
    const fetchQA = async () => {
      const terms = (await getQA(lang || "en")).data;
      dispatch(setQA(terms as QA[]));
    };
    fetchQA();
  }, []);

  const handleChange =
    (panel: string) => (event: React.SyntheticEvent, newExpanded: boolean) => {
      setExpanded(newExpanded ? panel : false);
    };
  const intl = useIntl();
  return (
    <HowToUseWrapper>
      {/* <TitleRow
        leftSide={<></>}
        center={
          <Title
            title={<>{"How to use Checkliste für deinen Aufbau"}</>}
            color={"#000"}
          />
        }
        rightSide={<></>}
      /> */}
      <MainContainer>
        <div className="side left">
          <FAQTitle>
            {intl.formatMessage({
              id: "Frequently asked questions (FAQ)",
              defaultMessage: "Frequently asked questions (FAQ)",
            })}
          </FAQTitle>
          <CustomerSupport>
            <span>
              {intl.formatMessage({
                id: "Customer support",
                defaultMessage: "Customer support",
              })}
              {":"}
            </span>
            <br />
            <a
              style={{ textDecoration: "underline" }}
              href="mailto:technik@siga.swiss"
            >
              technik@siga.swiss
            </a>
          </CustomerSupport>
        </div>
        <div className="side right">
          {qaContent.length > 0 ? (
            <div>
              {qaContent.map((c, i) => (
                <Accordion
                  expanded={expanded === `panel${i}`}
                  onChange={handleChange(`panel${i}`)}
                >
                  <AccordionSummary
                    aria-controls={`panel${i}d-content`}
                    id={`panel${i}d-header`}
                  >
                    <Typography>{c.question}</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography>{c.answer}</Typography>
                  </AccordionDetails>
                </Accordion>
              ))}
            </div>
          ) : (
            <ProgressContainer>
              <CircularProgress
                color="info"
                size={40}
                style={{ position: "relative" }}
              />
            </ProgressContainer>
          )}
        </div>
      </MainContainer>
      <BottomButtonContainer>
        <BackButton variant="text" color="inherit" onClick={() => navigate(-1)}>
          <KeyboardArrowLeft />
          {<FormattedMessage id={`Back`} defaultMessage={"Back"} />}
        </BackButton>
      </BottomButtonContainer>
    </HowToUseWrapper>
  );
};

export default HowToUse;
