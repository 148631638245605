import { useEffect, useRef, useState } from "react";
import { MEDIA_SMALL_PX, SIGA_IMAGE_TOP_OFFSET } from "../constants";

const useSticky = () => {
  const stickyRef = useRef(null);
  const [sticky, setSticky] = useState(false);
  const [offset, setOffset] = useState(
    window.innerWidth > MEDIA_SMALL_PX ? SIGA_IMAGE_TOP_OFFSET : 0
  );
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    if (!stickyRef.current) {
      return;
    }
    setOffset((stickyRef.current as any).offsetTop);
  }, [stickyRef, offset, windowWidth]);

  useEffect(() => {
    const setNewWindowWidth = () => {
      setWindowWidth(window.innerWidth);
    };
    if (!stickyRef.current) {
      return;
    }
    setOffset((stickyRef.current as any).offsetTop);
    window.addEventListener("resize", setNewWindowWidth);
    window.addEventListener("load", setNewWindowWidth);
    return () => {
      window.removeEventListener("resize", setNewWindowWidth);
      window.removeEventListener("load", setNewWindowWidth);
    };
  }, []);

  useEffect(() => {
    const handleScroll = () => {
      if (!stickyRef.current) {
        return;
      }
      setSticky(
        window.scrollY +
          (windowWidth > MEDIA_SMALL_PX ? SIGA_IMAGE_TOP_OFFSET : 0) >
          offset
      );
    };
    window.addEventListener("scroll", handleScroll);
    window.addEventListener("load", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
      window.removeEventListener("load", handleScroll);
    };
  }, [setSticky, stickyRef, offset, windowWidth]);
  return { stickyRef, sticky };
};

export default useSticky;
