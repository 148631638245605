import styled from "styled-components";
import { MAIN_GREY, WARNING_COLOR } from "../constants";
import warningIcon from "../assets/warning.svg";
import question from "../assets/question.svg";
import { Chip, TextField, styled as muiStyled } from "@mui/material";

export const WarningContainer = styled.div`
  color: ${WARNING_COLOR};
  font-size: 16px;
  background-image: url(${warningIcon});
  background-position: 20px 32px;
  background-repeat: no-repeat;
  background-color: ${MAIN_GREY};
  padding-left: 56px;
  padding-top: 32px;
  padding-bottom: 32px;
  padding-right: 32px;
  line-height: 20px;
  border-radius: 5px;
  @media print {
    background-position: 20px 20px;
  }
`;

export const CheckmarkTitleContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  margin-bottom: 0px;
  margin-top: 32px;
`;

export const CheckmarkIcon = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
`;

export const SigaFormTextField = styled(TextField)`
  flex: 0 0 90%;
  input {
    padding: 0 12px;
    height: 40px;
    border-radius: 2px;
  }
`;

export const HowToUseButton = muiStyled(Chip)`
background-image: url(${question});
background-repeat: no-repeat;
text-transform: initial !important;
background-position: 10px center;
color: rgba(0,0,0,0.5);
padding: 0 13px;
padding-left:36px;
border-radius: 40px;
height: 45px;
span {
  padding: 5px;
}
`;
